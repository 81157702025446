import React from "react";
import LoadingButton from "./LoadingButton";
import "../styles/InfoItem.css";

export default class SetupAccountModule extends React.Component {
  state = {
    payload: {},
    isSubmitting: false,
    buttonMessage:
      !localStorage.getItem("offerDurationDays") ||
      (localStorage.getItem("offerDurationDays") &&
        parseInt(localStorage.getItem("offerDurationDays"), 10) === 14)
        ? "Start free trial"
        : "Sign up",
    offerDurationDays: localStorage.getItem("offerDurationDays")
      ? parseInt(localStorage.getItem("offerDurationDays"), 10)
      : 14
  };

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  onChange = (e) => {
    e.persist();
    var payloadA = { ...this.state.payload };
    payloadA[e.target.name] = e.target.value;
    var payloadB = { ...this.state.payload };
    payloadB[e.target.name] = e.target.value;
    var payloadC = { ...this.state.payload };
    payloadC[e.target.name] = e.target.value;
    this.setState(() => ({
      payload: { ...payloadA, ...payloadB, ...payloadC }
    }));
  };

  onSubmit = () => {
    if (
      this.state.payload.email &&
      this.state.payload.password &&
      this.state.payload.UserFirstname
    ) {
      this.setState(() => ({
        emptyFields: false
      }));
      this.props.onStateChange(this.state.payload, true, true);
      //  this.setState({ buttonMessage: "Submitting" });
      //   setTimeout(() => this.setState({ buttonMessage: "Building" }), 3500);
      //  setTimeout(() => this.setState({ buttonMessage: "Finalising" }), 6500);
    } else {
      this.setState(() => ({
        emptyFields: true
      }));
    }
  };

  render() {
    const {
      InputLabelA,
      PayloadA,
      ValueA,
      PlaceholderTextA,
      InputLabelB,
      PayloadB,
      ValueB,
      PlaceholderTextB,
      InputLabelC,
      PayloadC,
      ValueC,
      PlaceholderTextC,
      submissionResponseStatus,
      submissionResponseReason
    } = this.props;

    const termsOfService = (
      <a
        href="https://airguideapp.com/terms-of-service.html"
        target="_blank"
        rel="noreferrer"
      >
        Terms of Service
      </a>
    );
    const privacyPolicy = (
      <a
        href="https://airguideapp.com/privacy-policy.html"
        target="_blank"
        rel="noreferrer"
      >
        Privacy Policy
      </a>
    );
    //  console.log(submissionResponseStatus);
    return (
      <>
        <div className="setupflow-heading-container">
          <div className="setupflow-heading">Complete setup</div>
        </div>
        <div className="setupflow-subheading">
          {this.state.offerDurationDays === 14 && (
            <div className="cta-button-subtext">
              Start your free 14 day trial, full access, no credit card
              required.
            </div>
          )}

          {this.state.offerDurationDays === 60 && (
            <div className="cta-button-subtext">
              Start your free 2 month subscription, no credit card required.
            </div>
          )}

          {this.state.offerDurationDays === 90 && (
            <div className="cta-button-subtext">
              Start your free 3 month subscription, no credit card required.
            </div>
          )}
        </div>
        <div className="infoitem-container">
          <div className="infoitem-form-container">
            <form className="infoitem-form" onSubmit={this.onSubmit}>
              <div className="infoitem-form-inner-container-left">
                <label className="infoitem-form-label"> {InputLabelC} </label>
                <input
                  type="text"
                  name={PayloadC}
                  className="infoitem-text-input"
                  placeholder={PlaceholderTextC}
                  defaultValue={ValueC}
                  value={this.state.payload.name}
                  onChange={this.onChange}
                  required
                  data-hj-allow
                />
              </div>

              <div className="infoitem-form-inner-container-left">
                <label className="infoitem-form-label"> {InputLabelA} </label>
                <input
                  type="email"
                  name={PayloadA}
                  className="infoitem-text-input"
                  placeholder={PlaceholderTextA}
                  defaultValue={ValueA}
                  value={this.state.payload.name}
                  onChange={this.onChange}
                  required
                  data-hj-allow
                />
              </div>

              <div className="infoitem-form-inner-container-left">
                <label className="infoitem-form-label"> {InputLabelB} </label>
                <input
                  type="password"
                  name={PayloadB}
                  className="infoitem-text-input"
                  placeholder={PlaceholderTextB}
                  defaultValue={ValueB}
                  value={this.state.payload.name}
                  onChange={this.onChange}
                  required
                />
              </div>
            </form>

            {
              // <button className="infoitem-save-button" onClick={this.onSubmit}>
              //  {this.state.buttonMessage}
              // </button>
            }

            <LoadingButton
              Loading={submissionResponseStatus === "fetching" ? true : false}
              Text={
                submissionResponseStatus === "fetching"
                  ? "Submitting"
                  : this.state.buttonMessage
              }
              OnClick={this.onSubmit}
            />

            <div className="cta-button-subtext">
              By clicking Start free trial, I agree to the {termsOfService} and{" "}
              {privacyPolicy}
            </div>

            {this.state.emptyFields && (
              <div className="infoitem-form-error-message">
                Please complete all fields to continue.
              </div>
            )}

            {submissionResponseStatus === "error" &&
              !this.state.emptyFields && (
                <div className="infoitem-form-error-message">
                  {submissionResponseReason === "USED_EMAIL" && (
                    <>This email address is already in use. Please try again.</>
                  )}
                </div>
              )}
          </div>
        </div>
      </>
    );
  }
}
