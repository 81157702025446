import React from "react";
import SetupPlaceName from "./SetupPlaceName";
import "../styles/DisplayCanvas.css";
import "../styles/InfoPage.css";
import "../styles/SetupFlow.css";

export default class SetupPlace extends React.Component {
  state = {
    //   payload: {
    //    AffiliateSource: localStorage.getItem("affiliateTrackingLabel")
    //      ? localStorage.getItem("affiliateTrackingLabel")
    //     : "",
    //   FreeOfferDays: localStorage.getItem("offerDurationDays")
    //     ? parseInt(localStorage.getItem("offerDurationDays"), 10)
    //     : 14
    //  },
    showPlace: true
  };

  // Scroll to top on page load & track page path
  componentDidMount() {
    window.scrollTo(0, 0);
  }

  handleRedirect = () => {
    this.props.history.push("/find-location");
  };

  render() {
    const { onStateChange } = this.props;

    // console.log(this.state);
    return (
      <>
        <SetupPlaceName
          {...this.props}
          //    SaveStatus={SaveStatus}
          ItemType={"property"} // Defines which dispatch to invoke
          ContainerClassName={"infoitem-form-inner-container-full"}
          ItemLabel={"New Place"}
          ItemHeading={"Property Title"}
          InputLabelA={"Choose a name for your property e.g. Seaside Escape"}
          PlaceholderText={"Enter property name here"}
          ValueA={""}
          FalseText={"Not entered"}
          PayloadA={"PropertyTitle"}
          SetupFlow={true}
          onStateChange={onStateChange}
          handleRedirect={this.handleRedirect}
        />
      </>
    );
  }
}
