import React from "react";
import ReactDOM from "react-dom";
import "./globalstyles.css";
import App from "./app.js";
import ReactGA from "react-ga4";
import { googleAnalytics4Id } from "./constants.js";

ReactGA.initialize(googleAnalytics4Id, {
  gaOptions: {
    allowLinker: true
  }
});

ReactGA.ga("require", "linker");
ReactGA.ga("linker:autoLink", ["airguideapp.com", "www.airguideapp.com"]);

ReactDOM.render(<App />, document.getElementById("root"));
