import React from "react";
import ReactGA from "react-ga";
import {
  googleAnalyticsId,
  dataApiRoot,
  workflowApiRoot,
  apiSecretToken
} from "../constants.js";
import Loading from "./Loading";
import "../styles/DisplayCanvas.css";
import "../styles/PartnersLanding.css";

ReactGA.initialize(googleAnalyticsId);

export default class PartnersLandingBespoke extends React.Component {
  state = {
    affiliateData: null,
    loaded: false
  };
  // Scroll to top on page load & track page path
  componentDidMount = () => {
    window.scrollTo(0, 0);
    ReactGA.pageview(window.location.pathname + window.location.search);

    const affiliateTrackingLabel = window.location.pathname.split("/")[2];
    localStorage.setItem("affiliateTrackingLabel", affiliateTrackingLabel);

    fetch(
      `${dataApiRoot}affiliates?constraints=[{%22key%22:%22affiliatetrackinglabel%22,%22constraint_type%22:%22equals%22,%22value%22:%22${affiliateTrackingLabel}%22}]`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${apiSecretToken}`
        }
      }
    )
      .then((res) => res.json())
      .then((data) => {
        //  console.log(data.response.results[0]);
        this.setState({ affiliateData: data.response.results[0] });

        const AffiliateTrackingLabel =
          data.response.results[0].AffiliateTrackingLabel;
        const AffiliateDiscountMonths =
          data.response.results[0].AffiliateDiscountMonths;
        const offerDurationDays =
          AffiliateDiscountMonths > 0 ? AffiliateDiscountMonths * 30 : 14;
        localStorage.setItem("offerDurationDays", offerDurationDays);
        const offerMessage =
          AffiliateDiscountMonths > 0
            ? `${AffiliateDiscountMonths} month free subscription`
            : "free 14 day trial";
        localStorage.setItem("offerMessage", offerMessage);
        //  console.log("offerMessage", offerMessage)
        // console.log("offerDurationDays", offerDurationDays);
        const payload = {
          AffiliateSource: AffiliateTrackingLabel,
          FreeOfferDays: offerDurationDays
        };
        this.props.onStateChange(payload, false, true);
      });

    fetch(
      `${dataApiRoot}systemcontent?constraints=[{%22key%22:%22detaillabel%22,%22constraint_type%22:%22equals%22,%22value%22:%22affiliate-landing%22}]`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${apiSecretToken}`
        }
      }
    )
      .then((res) => res.json())
      .then((data) => {
        //  console.log(data.response.results[0]);
        this.setState({
          affiliateSystemData: data.response.results[0],
          loaded: true
        });

        if (affiliateTrackingLabel) {
          fetch(`${workflowApiRoot}referral-link-click`, {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Accept: "application/json",
              Authorization: `Bearer ${apiSecretToken}`
            },
            body: JSON.stringify({
              affiliateTrackingLabel: affiliateTrackingLabel
            })
          });
        }
      });
  };

  componentDidUpdate = () => {
    ReactGA.pageview(window.location.pathname + window.location.search);
  };

  onButtonClick = () => {
    this.props.history.push(`/start`);
  };

  onLinkClick = () => {
    const affiliateCampaignLabel = localStorage.getItem(
      "affiliateTrackingLabel"
    );
    window.location.href = `https://airguideapp.com?utm_source=affiliate-landing-page&utm_medium=affiliate&utm_campaign=${affiliateCampaignLabel}`;
  };

  render() {
    if (!this.state.loaded) {
      return <Loading />;
    } else {
      if (this.state.affiliateData && this.state.affiliateSystemData) {
        const h1Text =
          //
          this.state.affiliateData.LandingHeaderMessage;
        // ? `${this.state.affiliateData.LandingHeaderMessage} - FREE for ${this.state.affiliateData.AffiliateDiscountMonths} months`
        // : this.state.affiliateData.AffiliateDiscountMonths > 0 &&
        //    !this.state.affiliateData.LandingHeaderMessage
        //  ? `Get your digital guidebook now - FREE for ${this.state.affiliateData.AffiliateDiscountMonths} months`
        //  : "Get your digital guidebook now";

        const h2Text =
          // this.state.affiliateData.AffiliateDiscountMonths > 0 &&
          this.state.affiliateData.LandingSubHeaderMessage;
        //   ? this.state.affiliateData.LandingSubHeaderMessage
        //   : this.state.affiliateData.AffiliateDiscountMonths > 0 &&
        //     !this.state.affiliateData.LandingSubHeaderMessage
        //   ? `airguide™ and ${this.state.affiliateData.AffiliateName} have teamed up to bring you this fantastic offer`
        //   : `FREE for 14 days with ${this.state.affiliateData.AffiliateName}`;

        return (
          <>
            <div className="affiliate-canvas">
              <div className="affiliate-canvas-inner">
                <div className="landing-cover-outer-container">
                  <div className="landing-cover-mid-container">
                    <div className="landing-cover-inner-container">
                      <div className="landing-cover-image-and-logo-container">
                        <div className="landing-cover-image-container">
                          <img
                            className={"landing-cover-image"}
                            src={this.state.affiliateSystemData.DetailImage}
                            alt={this.state.affiliateData.AffiliateName}
                          />
                        </div>
                        <div>
                          {this.state.affiliateData.AffiliateLogo && (
                            <img
                              className={"landing-partner-logo"}
                              src={this.state.affiliateData.AffiliateLogo}
                              alt={this.state.affiliateData.AffiliateName}
                            />
                          )}
                        </div>
                      </div>

                      <div className="landing-cover-content-container">
                        <h1 className="landing-h1">{h1Text}</h1>

                        <h2 className="landing-h2">{h2Text}</h2>

                        <div className="landing-cta-button-wrapper">
                          <input
                            type="submit"
                            className="landing-cta-button"
                            onClick={this.onButtonClick}
                            value={"Try free"}
                          />
                        </div>

                        <div
                          className="landing-link-button"
                          onClick={this.onLinkClick}
                        >
                          {"What is airguide? Find out more >"}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        );
      } else
        return (
          <>
            <Loading />
          </>
        );
    }
  }
}
