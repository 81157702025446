import React from "react";
import "../styles/InfoItem.css";
import { googleMapsApiKey } from "../constants.js";
import Script from "react-load-script";

export default class SetupPlaceMapAuto extends React.Component {
  state = {
    payload: {}
  };

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  handleScriptLoad = () => {
    this.createMap();
  };

  createMap = () => {
    // Declare Options For Autocomplete
    const options = {
      types: ["address"]
    }; // To disable any eslint 'google not defined' errors

    // Initialize Google Autocomplete
    /*global google*/
    this.autocomplete = new google.maps.places.Autocomplete(
      document.getElementById("autocomplete"),
      options
    );

    // Avoid paying for data that you don't need by restricting the set of
    // place fields that are returned to just the address components and formatted
    // address.
    this.autocomplete.setFields([
      "address_components",
      "formatted_address",
      "geometry"
    ]);

    // Fire Event when a suggested name is selected
    this.autocomplete.addListener("place_changed", this.handlePlaceSelect);
  };

  handlePlaceSelect = () => {
    // Extract City From Address Object
    const addressObject = this.autocomplete.getPlace();
    const address = addressObject.address_components;
    //  console.log(address);

    function extractFromAdress(components, type) {
      for (var i = 0; i < components.length; i++)
        for (var j = 0; j < components[i].types.length; j++)
          if (components[i].types[j] === type) return components[i].long_name;
      return "";
    }

    var streetNumber = extractFromAdress(address, "street_number");
    var postCode = extractFromAdress(address, "postal_code");
    var street = extractFromAdress(address, "route");
    var town = extractFromAdress(address, "locality");
    var postalTown = extractFromAdress(address, "postal_town");
    var county = extractFromAdress(address, "administrative_area_level_2");
    var state = extractFromAdress(address, "administrative_area_level_1");
    var country = extractFromAdress(address, "country");

    const Payload = {
      GeoLocationAddressLineOne: streetNumber + " " + street,
      GeoLocationAddressLineTwo: "",
      GeoLocationTownCity: postalTown ? postalTown : town,
      GeoLocationCounty: county,
      GeoLocationState: state,
      GeoLocationPostcode: postCode,
      GeoLocationCountry: country,
      GeoLocationFormattedAddress: addressObject.formatted_address,
      PropertyDisplayAddress: addressObject.formatted_address,
      GeoLocationLat: addressObject.geometry.location.lat(),
      GeoLocationLng: addressObject.geometry.location.lng()
    };

    // Check if address is valid
    if (address) {
      this.setState({
        payload: Payload
      });
    }

    this.handleSubmit();
    this.props.onLocationUpdate(
      Payload.GeoLocationLat,
      Payload.GeoLocationLng,
      Payload.GeoLocationFormattedAddress
    );
  };

  handleSubmit = () => {
    this.props.onStateChange(this.state.payload, false, true);
  };

  render() {
    const { InputLabelA } = this.props;
    return (
      <>
        <div className="infoitem-form-inner-container-full">
          <label className="infoitem-form-label"> {InputLabelA} </label>
          <Script
            url={`https://maps.googleapis.com/maps/api/js?key=${googleMapsApiKey}&libraries=places`}
            onLoad={this.handleScriptLoad}
          />
          <input
            className="infoitem-text-input"
            id="autocomplete"
            placeholder="Enter address and select from list"
            defaultValue=""
            required
            data-hj-allow
          />
        </div>
      </>
    );
  }
}
