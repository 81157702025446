import React, { useState } from "react";
import { HexColorPicker, HexColorInput } from "react-colorful";
import "react-colorful/dist/index.css";
import "../styles/ColourPicker.css";
import "../styles/InfoItem.css";

export default function SetupThemeColours(props) {
  const ValueA = "#1E8FC1";
  const ValueB = "#1E8FC1";

  const [colorA, setColorA] = useState(ValueA);
  const [colorB, setColorB] = useState(ValueB);

  const handleRedirect = () => {
    props.history.push("/complete-setup");

    props.onStateChange(
      {
        ThemeLabel: "custom",
        PropertyHexPrimary: colorA,
        PropertyHexSecondary: colorB
      },
      false,
      true
    );
  };

  return (
    <>
      <div className="setupflow-heading-container">
        <div className="setupflow-heading">Choose your colour scheme</div>
      </div>

      <div className="infoitem-container">
        <div className={"show"}>
          <div className="infoitem-form-container">
            <form className="infoitem-form">
              <div className="infoitem-form-inner-container-left">
                <label className="infoitem-form-label">Icons and buttons</label>

                <div className="colour-picker-outer">
                  <HexColorPicker color={colorA} onChange={setColorA} />

                  <div className="colour-picker-input-and-hashtag-container">
                    <div> # </div>
                    <HexColorInput
                      className="colour-picker-text-input"
                      color={colorA}
                      onChange={setColorA}
                    />
                  </div>
                </div>
              </div>

              <div className="infoitem-form-inner-container-right">
                <label className="infoitem-form-label">Menu and banners</label>

                <div className="colour-picker-outer">
                  <HexColorPicker color={colorB} onChange={setColorB} />

                  <div className="colour-picker-input-and-hashtag-container">
                    <div> # </div>
                    <HexColorInput
                      className="colour-picker-text-input"
                      color={colorB}
                      onChange={setColorB}
                    />
                  </div>
                </div>
              </div>
            </form>
            <button className="infoitem-save-button" onClick={handleRedirect}>
              Continue
            </button>
          </div>
        </div>
      </div>
    </>
  );
}
