import React from "react";
import "../styles/InfoItem.css";
import { googleMapsApiKey } from "../constants.js";
import Script from "react-load-script";
import MapPins from "../pins/MapPins.js";

export default class SetupPlaceMapManual extends React.Component {
  state = {
    GeoLocationAddressLineOne: "",
    GeoLocationAddressLineTwo: "",
    GeoLocationTownCity: "",
    GeoLocationCounty: "",
    GeoLocationPostcode: "",
    GeoLocationCountry: "",
    GeoLocationFormattedAddress: "",
    lat: 52.689182,
    lng: -1.640071,
    dragMessage: "Drag marker to select exact location"
  };

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  handleScriptLoad = () => {
    this.createMap();
  };

  createMap = () => {
    this.googlemap = new window.google.maps.Map(
      document.getElementById("googlemap-manual"),
      {
        zoom: 1,
        center: {
          lat: this.state.lat,
          lng: this.state.lng
        },
        disableDefaultUI: true,
        fullscreenControl: false,
        streetViewControl: false,
        mapTypeControl: false,
        zoomControl: true,
        gestureHandling: "greedy",
        styles: [
          {
            featureType: "administrative",
            elementType: "geometry",
            stylers: [
              {
                visibility: "off"
              }
            ]
          },
          {
            featureType: "poi",
            stylers: [
              {
                visibility: "off"
              }
            ]
          },
          {
            featureType: "road",
            elementType: "labels.icon",
            stylers: [
              {
                visibility: "off"
              }
            ]
          },
          {
            featureType: "transit",
            stylers: [
              {
                visibility: "on"
              }
            ]
          }
        ]
      }
    );

    this.marker = new window.google.maps.Marker({
      position: this.googlemap.getCenter(),
      map: this.googlemap,
      icon: {
        url: MapPins.home,
        scaledSize: new window.google.maps.Size(40, 54.5)
      },
      optimized: false,
      clickable: false,
      draggable: true
    });

    this.marker.addListener("dragstart", () => {
      this.setState({
        dragMessage: "Dragging..."
      });
    });

    this.marker.addListener("dragend", (evt) => {
      this.setState({
        lat: evt.latLng.lat(),
        lng: evt.latLng.lng(),
        dragMessage: "Location selected"
      });
    });
  };

  onChange = (e) => {
    e.persist();
    this.setState({ [e.target.name]: e.target.value });
  };

  onSubmit = (e) => {
    e.preventDefault();

    var geoLocationLineOne = this.state.GeoLocationAddressLineOne
      ? this.state.GeoLocationAddressLineOne + ", "
      : "";
    var geoLocationLineTwo = this.state.GeoLocationAddressLineTwo
      ? this.state.GeoLocationAddressLineTwo + ", "
      : "";
    var geoLocationTownCity = this.state.GeoLocationTownCity
      ? this.state.GeoLocationTownCity + ", "
      : "";
    var geoLocationCounty = this.state.GeoLocationCounty
      ? this.state.GeoLocationCounty + ", "
      : "";
    var geoLocationPostcode = this.state.GeoLocationPostcode
      ? this.state.GeoLocationPostcode + ", "
      : "";
    var geoLocationCountry = this.state.GeoLocationCountry
      ? this.state.GeoLocationCountry + ", "
      : "";

    var geoLocationFormattedAddressConcat =
      geoLocationLineOne +
      geoLocationLineTwo +
      geoLocationTownCity +
      geoLocationCounty +
      geoLocationPostcode +
      geoLocationCountry;

    var geoLocationFormattedAddressTrimmed = geoLocationFormattedAddressConcat.substring(
      0,
      geoLocationFormattedAddressConcat.length - 2
    );

    const Payload = {
      GeoLocationAddressLineOne: this.state.GeoLocationAddressLineOne,
      GeoLocationAddressLineTwo: this.state.GeoLocationAddressLineTwo,
      GeoLocationTownCity: this.state.GeoLocationTownCity,
      GeoLocationCounty: this.state.GeoLocationCounty,
      GeoLocationPostcode: this.state.GeoLocationPostcode,
      GeoLocationCountry: this.state.GeoLocationCountry,
      GeoLocationFormattedAddress: geoLocationFormattedAddressTrimmed,
      PropertyDisplayAddress: geoLocationFormattedAddressTrimmed,
      GeoLocationLat: this.state.lat,
      GeoLocationLng: this.state.lng
    };

    this.props.onLocationUpdate(
      Payload.GeoLocationLat,
      Payload.GeoLocationLng,
      Payload.GeoLocationFormattedAddress
    );
    this.props.onStateChange(Payload, false, true);
  };

  render() {
    const { SaveStatus, ItemLabel, ValueA } = this.props;

    return (
      <>
        <div className="infoitem-form-inner-container-full">
          <label className="infoitem-form-label">Building and street</label>
          <input
            type="text"
            name="GeoLocationAddressLineOne"
            className="infoitem-text-input"
            placeholder="e.g. Flat 42, Princes House"
            defaultValue={ValueA}
            value={this.state.GeoLocationAddressLineOne}
            onChange={this.onChange}
            required
            data-hj-allow
          />
        </div>

        <div className="infoitem-form-inner-container-full">
          <input
            type="text"
            name="GeoLocationAddressLineTwo"
            className="infoitem-text-input"
            placeholder="e.g. 2 Princes Street"
            defaultValue={ValueA}
            value={this.state.GeoLocationAddressLineTwo}
            onChange={this.onChange}
            required
            data-hj-allow
          />
        </div>

        <div className="infoitem-form-inner-container-left">
          <label className="infoitem-form-label"> Town or city </label>
          <input
            type="text"
            name="GeoLocationTownCity"
            className="infoitem-text-input"
            placeholder="Enter town or city"
            defaultValue={ValueA}
            value={this.state.GeoLocationTownCity}
            onChange={this.onChange}
            required
            data-hj-allow
          />
        </div>

        <div className="infoitem-form-inner-container-left">
          <label className="infoitem-form-label"> County</label>
          <input
            type="text"
            name="GeoLocationCounty"
            className="infoitem-text-input"
            placeholder="Enter county"
            defaultValue={ValueA}
            value={this.state.GeoLocationCounty}
            onChange={this.onChange}
            required
            data-hj-allow
          />
        </div>

        <div className="infoitem-form-inner-container-left">
          <label className="infoitem-form-label"> Postcode </label>
          <input
            type="text"
            name="GeoLocationPostcode"
            className="infoitem-text-input"
            placeholder="Enter postcode"
            defaultValue={ValueA}
            value={this.state.GeoLocationPostcode}
            onChange={this.onChange}
            required
            data-hj-allow
          />
        </div>

        <div className="infoitem-form-inner-container-left">
          <label className="infoitem-form-label"> Country </label>
          <input
            type="text"
            name="GeoLocationCountry"
            className="infoitem-text-input"
            placeholder="Enter country"
            defaultValue={ValueA}
            value={this.state.GeoLocationCountry}
            onChange={this.onChange}
            required
            data-hj-allow
          />
        </div>

        <div className="infoitem-form-inner-container-map">
          <label className="infoitem-form-label-map">
            {this.state.dragMessage}
          </label>

          <Script
            url={`https://maps.googleapis.com/maps/api/js?key=${googleMapsApiKey}&libraries=places`}
            onLoad={this.handleScriptLoad}
          />
          <div
            id="googlemap-manual"
            style={{ width: "auto", height: "275px", marginBottom: "12px" }}
          />
        </div>

        {(this.state.GeoLocationAddressLineOne ||
          this.state.GeoLocationAddressLineTwo ||
          this.state.GeoLocationTownCity ||
          this.state.GeoLocationCounty ||
          this.state.GeoLocationPostcode ||
          this.state.GeoLocationCountry) && (
          <div className="infoitem-form-inner-container-left">
            <button onClick={this.onSubmit} className="infoitem-save-button">
              {SaveStatus === `${ItemLabel}-fetching` ? "Saving..." : "Save"}
            </button>
            <div style={{ height: "12px" }}> </div>
          </div>
        )}
      </>
    );
  }
}
