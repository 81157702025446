import React from "react";
import SetupAccountModule from "./SetupAccountModule";
import "../styles/DisplayCanvas.css";
import "../styles/InfoPage.css";
import "../styles/SetupFlow.css";

export default class SetupAccount extends React.Component {
  
  componentDidMount() {
    window.scrollTo(0, 0);
  }

  handleRedirect = () => {
    this.props.history.push("/find-location");
  };

  render() {
    const {
      onStateChange,
      handleFinalSubmit,
      submissionResponseStatus,
      submissionResponseReason
    } = this.props;

    return (
      <>
        <SetupAccountModule
          {...this.props}
          ContainerClassName={"infoitem-form-inner-container-full"}
          InputLabelA={"Email address"}
          PayloadA={"email"}
          ValueA={""}
          PlaceholderTextA={"Enter email address here"}
          InputLabelB={"Password"}
          PayloadB={"password"}
          ValueB={""}
          PlaceholderTextB={"Enter password here"}
          InputLabelC={"Your first name"}
          PayloadC={"UserFirstname"}
          ValueC={""}
          PlaceholderTextC={"Enter your first name here"}
          onStateChange={onStateChange}
          handleFinalSubmit={handleFinalSubmit}
          handleRedirect={this.handleRedirect}
          submissionResponseStatus={submissionResponseStatus}
          submissionResponseReason={submissionResponseReason}
        />
      </>
    );
  }
}
