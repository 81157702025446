import React from "react";
import { workflowApiRoot, apiSecretToken } from "../constants.js";
import Loading from "./Loading";

export default class ReferralLanding extends React.Component {
  state = {
    affiliateTrackingLabel: window.location.pathname.split("/")[1],
    fetchSuccess: false
  };

  componentDidMount = () => {
    window.scrollTo(0, 0);
    // console.log("ComponentDidMount: affiliateTrackingLabel", this.state.affiliateTrackingLabel);
    const affiliateTrackingLabel = this.state.affiliateTrackingLabel;
    localStorage.setItem("affiliateTrackingLabel", affiliateTrackingLabel);
    localStorage.setItem("offerDurationDays", 14);
    localStorage.setItem("offerMessage", "free 14 day trial");

    if (affiliateTrackingLabel) {
      fetch(`${workflowApiRoot}referral-link-click`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${apiSecretToken}`
        },
        body: JSON.stringify({ affiliateTrackingLabel: affiliateTrackingLabel })
      })
        .then((response) => response.json())
        .then((results) => {
          //  console.log("Fetch Response", results.status);
          if (results.status === "success") {
            this.setState({ fetchSuccess: true });
          }
        });
    }
  };

  render() {
    const affiliateTrackingLabel = this.state.affiliateTrackingLabel;

    if (this.state.fetchSuccess) {
      window.location.href = `https://airguideapp.com/?utm_source=referral-landing-page&utm_medium=referral&utm_campaign=${affiliateTrackingLabel}`;
    }

    return (
      <>
        <Loading />
      </>
    );
  }
}
