import React from "react";
import ReactLoading from "react-loading";
import "../styles/LoadingButton.css";

export default class LoadingButton extends React.Component {
  render() {
    const { Loading, Text, OnClick } = this.props;
    return (
      <button className="button-with-loading" onClick={OnClick}>
        <div
          className={
            Loading
              ? "button-with-loading-text-loading"
              : "button-with-loading-text"
          }
        >
          {Text}
        </div>
        {Loading && (
          <ReactLoading
            type={"spin"}
            color={"white"}
            height={"20%"}
            width={"20%"}
          />
        )}
      </button>
    );
  }
}
