import React from "react";
import "../styles/InfoItem.css";
import CirclePlusIcon from "../icons/CirclePlusIcon";
import SetupCheckMark from "./SetupCheckMark";

export default class ImageUploader extends React.Component {
  state = {
    selectedFile: this.props.selectedPropertyCoverImage,
    payload: {},
    inputKey: null
  };

  onChange = (e) => {
    e.persist();
    //   var payloadA = { ...this.state.payload };
    //  payloadA[e.target.name] = e.target.files[0];

    let imageFile = e.target.files[0];
    const reader = new FileReader();
    reader.onload = this.handleReaderLoaded.bind(this);
    // reader.readAsBinaryString(file); Bubble API
    reader.readAsArrayBuffer(imageFile);
    this.setState({
      imageFilename: imageFile.name
    });
    //  console.log("imageFile", imageFile);
    this.onClick();
  };

  handleReaderLoaded = (readerEvt) => {
    //  const { PayloadA } = this.props;
    // let binaryString = readerEvt.target.result;
    let imageReaderFile = readerEvt.target.result;
    //  console.log("imageReaderFile", imageReaderFile);
    var imageBlob = new Blob([imageReaderFile], { type: "image/jpeg" });
    var imageUrlCreator = window.URL || window.webkitURL;
    var imageUrl = imageUrlCreator.createObjectURL(imageBlob);

    this.setState({
      selectedFile: imageReaderFile,
      payload: {
        //       [PayloadA]:
        //{
        //      filename: this.state.imageFilename,
        //      contents: btoa(binaryString),
        //     private: false
        //  },
        imageFilename: this.state.imageFilename,
        selectedImageFile: imageReaderFile
      },
      imageFilePreview: imageUrl
      //  }
    });
    //   console.log(this.state);
    this.props.onStateChange(
      { ThemeLabel: "custom", ...this.state.payload },
      false,
      true
    );
  };

  onClick = () => {
    let randomString = Math.random().toString(36);
    this.setState({
      selectedFile: null,
      inputKey: randomString
    });
  };

  render() {
    const {
      ItemLabel,
      PayloadA,
      SelectedTheme,
      containerClassName
    } = this.props;

    return (
      <>
        <div className={containerClassName}>
          <form
            className={
              SelectedTheme === "custom"
                ? "image-banner-selected"
                : "image-banner-empty"
            }
            onSubmit={this.onSubmit}
          >
            <div
              className={
                SelectedTheme === "custom"
                  ? "imageuploader-form-inner-container-left-selected"
                  : "imageuploader-form-inner-container-left"
              }
            >
              <input
                type="file"
                key={this.state.inputKey || ""} // Required to clear file input value
                id={`file-upload-image-${ItemLabel}`}
                name={PayloadA}
                className="imageuploader-image-input"
                value={this.state.payload.name}
                onChange={this.onChange}
                required
              />

              <label
                htmlFor={`file-upload-image-${ItemLabel}`}
                className={
                  SelectedTheme === "custom"
                    ? "imageuploader-custom-file-upload-selected"
                    : "imageuploader-custom-file-upload"
                }
              >
                {SelectedTheme === "custom" && (
                  <img
                    className="imageuploader-image"
                    src={
                      //this.props.selectedPropertyCoverImage
                      //   ? //this.props.selectedPropertyCoverImage
                      this.state.imageFilePreview
                      //   : this.state.imageFilePreview
                      // `data:image/jpeg;base64,${this.props.selectedPropertyCoverImage.contents}`
                      // : `data:image/jpeg;base64,${this.state.selectedFile}`
                    }
                    alt={ItemLabel}
                  />
                )}
                {SelectedTheme !== "custom" && (
                  <div className="imageuploader-custom-file-upload-inner-container">
                    <CirclePlusIcon className="imageuploader-custom-file-upload-icon" />

                    <div className="imageuploader-custom-file-upload-text">
                      Upload image
                    </div>
                  </div>
                )}
              </label>
            </div>

            {this.state.selectedFile && (
              <div className="image-banner-heading-and-chevron-wrapper">
                <div className="image-banner-chevron-icon-wrapper">
                  {SelectedTheme === "custom" && (
                    <>
                      <SetupCheckMark
                        IsChecked={true}
                        IsRequired={true}
                        IsCustomContent={true}
                        IsNewCustomItem={false}
                      />
                      <div className="image-banner-heading">Your Image</div>
                    </>
                  )}
                </div>
              </div>
            )}
          </form>

          <div className="infoitem-section-break-wrapper">
            <div className="infoitem-section-break"></div>
          </div>
        </div>
      </>
    );
  }
}
