import React from "react";

function CirclePlusIcon(props) {
  return (
    <svg width={612} height={612} viewBox="0 0 612 612" {...props}>
      <path d="M306 0C136.992 0 0 136.992 0 306s136.992 306 306 306 306-137.012 306-306S475.008 0 306 0zm0 573.75C158.125 573.75 38.25 453.875 38.25 306S158.125 38.25 306 38.25 573.75 158.125 573.75 306 453.875 573.75 306 573.75zm114.75-286.875h-95.625V191.25c0-10.557-8.568-19.125-19.125-19.125s-19.125 8.568-19.125 19.125v95.625H191.25c-10.557 0-19.125 8.568-19.125 19.125s8.568 19.125 19.125 19.125h95.625v95.625c0 10.557 8.568 19.125 19.125 19.125s19.125-8.568 19.125-19.125v-95.625h95.625c10.557 0 19.125-8.568 19.125-19.125s-8.568-19.125-19.125-19.125z" />
    </svg>
  );
}

export default CirclePlusIcon;
