import React from "react";

function UpperRightArrowSquare(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={612}
      height={612}
      viewBox="0 0 612 612"
      {...props}
    >
      <path d="M568.286 524.571c0 23.759-19.978 43.715-43.715 43.715l-438.585.721c-23.759 0-42.993-19.256-42.993-42.993l.721-438.585c0-23.759 19.978-43.714 43.714-43.714h240.429V0H87.429C39.933 0 0 48.545 0 96.019v429.974C0 573.51 38.49 612 85.986 612H515.96c47.495 0 96.04-39.933 96.04-87.429V284.143h-43.714v240.428zM589.662 0h-152.52c-12.393.022-21.835 10.032-21.856 21.857-.022 11.825 9.464 21.879 21.856 21.857l96.041-.7-329.759 314.525c-8.721 8.328-8.721 21.813 0 30.119 8.721 8.328 22.863 8.328 31.583 0L567.63 70.424l.656 104.434c-.022 11.824 9.464 21.879 21.856 21.857 12.394-.022 21.836-10.033 21.857-21.857V21.311C612.021 9.486 602.055-.022 589.662 0z" />
    </svg>
  );
}

export default UpperRightArrowSquare;
