import React from "react";
import "../styles/DisplayCanvas.css";
import "../styles/ImageBanners.css";
import SetupImageBanner from "./SetupImageBanner";
import ImageUploader from "./ImageUploader";

export default class SetupTheme extends React.Component {
  state = {
    loading: true
  };

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  handleRedirect = () => {
    const { SelectedTheme } = this.props;
    if (SelectedTheme !== "custom") {
      this.props.history.push("/complete-setup");
    }
    if (SelectedTheme === "custom") {
      this.props.history.push("/pick-branding");
    }
  };

  render() {
    const { themeData, SelectedTheme, selectedPropertyCoverImage } = this.props;

    if (themeData) {
      return (
        <>
          <div className="setupflow-heading-container">
            <div className="setupflow-heading">
              Pick a theme or upload a photo
            </div>
          </div>
          <div className="image-banners-container">
            {themeData.map((item) => (
              <div
                key={item._id}
                className={
                  themeData.indexOf(item) === 0 ||
                  themeData.indexOf(item) === 6 ||
                  themeData.indexOf(item) === 12
                    ? "image-banner-container-1"
                    : themeData.indexOf(item) === 1 ||
                      themeData.indexOf(item) === 7 ||
                      themeData.indexOf(item) === 13
                    ? "image-banner-container-2"
                    : themeData.indexOf(item) === 2 ||
                      themeData.indexOf(item) === 8 ||
                      themeData.indexOf(item) === 14
                    ? "image-banner-container-3"
                    : themeData.indexOf(item) === 3 ||
                      themeData.indexOf(item) === 9 ||
                      themeData.indexOf(item) === 15
                    ? "image-banner-container-4"
                    : themeData.indexOf(item) === 4 ||
                      themeData.indexOf(item) === 10 ||
                      themeData.indexOf(item) === 16
                    ? "image-banner-container-5"
                    : themeData.indexOf(item) === 5 ||
                      themeData.indexOf(item) === 11 ||
                      themeData.indexOf(item) === 17
                    ? "image-banner-container-6"
                    : "image-banner-container-1"
                }
              >
                <SetupImageBanner
                  {...this.props}
                  ItemData={item}
                  SelectedTheme={SelectedTheme}
                  onStateChange={this.props.onStateChange}
                  handleRedirect={this.handleRedirect}
                />
              </div>
            ))}

            <ImageUploader
              {...this.props}
              SelectedTheme={SelectedTheme}
              selectedPropertyCoverImage={selectedPropertyCoverImage}
              onStateChange={this.props.onStateChange}
              handleRedirect={this.handleRedirect}
              propertyId={null}
              ItemType={"property"} // Defines which dispatch to invoke
              ItemLabel={"Cover Image"}
              ItemHeading={"Cover Image"}
              ItemDescription={"For best results pick a landscape image"}
              InputLabelA={""}
              ValueA={null}
              IsChecked={null}
              IsRequired={false}
              FalseText={"Not selected"}
              PayloadA={"PropertyCoverImage"} // Defines which database label to update
              containerClassName={"image-banner-container-4"}
            />
          </div>

          <button
            className="infoitem-save-button"
            onClick={this.handleRedirect}
          >
            Continue
          </button>
        </>
      );
    }
    return <></>;
  }
}
