import React from "react";
import ReactLoading from "react-loading";
import "../styles/Loading.css";
import logo from "../images/airguide-logo-header.png";

export default class IFrameLoading extends React.Component {
  render() {
    const { LoadingText } = this.props;
    return (
      <div className="iframe-loading-canvas">
        
          <img src={logo} alt="logo" className="iframe-loading-logo-wrapper" />
        

        <div className="iframe-loading-text">
          {LoadingText && LoadingText}
          {!LoadingText && "loading..."}
        </div>

        <div style={{ marginTop: "15px" }}>
          <ReactLoading
            type={"spin"}
            color={"var(--UIcolour1)"}
            height={1}
            width={40}
          />
        </div>
      </div>
    );
  }
}