import React from "react";
import { workflowApiRoot, apiSecretToken } from "../constants";
import "../styles/InfoItem.css";
import "../styles/SetupFlow.css";

export default class CreatePropertyModule extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      payload: { PropertyID: this.generateRandomNumber(6) },
      redirect: false
    };
  }

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  generateRandomNumber = (length) => {
    var result = "";
    var characters = "0123456789";
    var charactersLength = characters.length;
    for (var i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  };

  generateWeblink = (payload) => {
    var result = payload
      .replace(/[^a-zA-Z0-9]/g, "-")
      .replace(/----/g, "-")
      .replace(/---/g, "-")
      .replace(/--/g, "-")
      .toLowerCase();
    return result;
  };

  onChange = (e) => {
    e.persist();
    var payloadA = { ...this.state.payload };
    payloadA[e.target.name] = e.target.value.trim();
    payloadA["PropertyID"] = this.generateWeblink(payloadA.PropertyTitle);
    this.setState(() => ({
      payload: { ...payloadA }
    }));
  };

  handleSubmit = () => {
    this.props.onStateChange(this.state.payload, false, true);
    if (this.state.payload.PropertyTitle) {
      this.props.handleRedirect();

      fetch(`${workflowApiRoot}check-property-id`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${apiSecretToken}`
        },
        body: JSON.stringify(this.state.payload)
      })
        .then((response) => response.json())
        .then((results) => {
          //  console.log("Check Property Id Response", results);

          if (!results.response.propertyIdAvailable) {
            var originalPayload = { ...this.state.payload };

            var newPropertyId =
              this.state.payload.PropertyID +
              "-" +
              this.generateRandomNumber(6);

            originalPayload["PropertyID"] = newPropertyId;
            var updatedPayload = { ...originalPayload };
            this.props.onStateChange(updatedPayload, false, true);
          }

          if (results.response.propertyIdAvailable) {
            this.props.onStateChange(this.state.payload, false, true);
          }
        });
    } else {
      this.setState(() => ({
        propertyTitleIsEmpty: true
      }));
    }
  };

  handleRedirect = () => {
    this.props.history.push("/find-location");
  };

  render() {
    const {
      ItemDescription,
      InputLabelA,
      PayloadA,
      ContainerClassName,
      PlaceholderText
    } = this.props;

    return (
      <>
        <div className="setupflow-heading-container">
          <div className="setupflow-heading">
            Create your airguide
            <span style={{ fontSize: "14px", verticalAlign: "text-top" }}>
              ™
            </span>{" "}
            in just 3 simple steps
          </div>
        </div>
        <div className="setupflow-subheading"></div>
        <div className="infoitem-container">
          <div className="infoitem-value">{ItemDescription}</div>

          <div className="infoitem-form-container">
            <form className="infoitem-form">
              <div className={`${ContainerClassName}`}>
                <label className="infoitem-form-label"> {InputLabelA} </label>
                <input
                  type="text"
                  name={PayloadA}
                  className="infoitem-text-input"
                  placeholder={PlaceholderText}
                  value={this.state.payload.name}
                  onChange={this.onChange}
                  required
                  data-hj-allow
                />
              </div>
            </form>

            <button
              className="infoitem-save-button"
              onClick={this.handleSubmit}
            >
              Start now
            </button>

            {this.state.propertyTitleIsEmpty && (
              <div className="infoitem-form-error-message">
                Please enter place name to continue.
              </div>
            )}
          </div>
        </div>
      </>
    );
  }
}
