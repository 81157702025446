// Set liveStatus "true" for live data or "false" for test data
const liveStatus = true;

// Workflow API
export const workflowApiRoot = liveStatus
  ? `${process.env.REACT_APP_WORKFLOW_API_LIVE}`
  : `${process.env.REACT_APP_WORKFLOW_API_DEV}`;

// Data API
export const dataApiRoot = liveStatus
  ? `${process.env.REACT_APP_DATA_API_LIVE}`
  : `${process.env.REACT_APP_DATA_API_DEV}`;

// Backend API Secret Token
export const apiSecretToken = `${process.env.REACT_APP_API_SECRET_TOKEN}`;

// Root URL for live End-user App
export const appRootUrl = liveStatus
  ? "https://air.guide/"
  : "https://dev.air.guide/";

export const dashboardUrl = liveStatus
  ? "https://my.airguideapp.com/"
  : "https://dev-my.airguideapp.com/";

export const websiteUrl = liveStatus
  ? "https://www.airguideapp.com/"
  : "https://dev.airguideapp.com/";

// Storage Root
export const storageRootUrl = `${process.env.REACT_APP_STORAGE_ROOT}`;

export const storageApiKey = `${process.env.REACT_APP_STORAGE_ACCESS_KEY}`;

// CDN Root
export const cdnRootUrl = `${process.env.REACT_APP_CDN_ROOT}`;

// Google Maps API Key
export const googleMapsApiKey = liveStatus
  ? `${process.env.REACT_APP_GOOGLE_MAPS_API_KEY_LIVE}`
  : `${process.env.REACT_APP_GOOGLE_MAPS_API_KEY_TEST}`;

// Google Analytics Id
export const googleAnalyticsId = `${process.env.REACT_APP_GOOGLE_ANALYTICS_TRACKING_ID}`;

// Google Analytics 4 Tracking Id
export const googleAnalytics4Id = "G-RKK6XE9SSS";

// System Id to identify application type
export const systemId = "100000";
