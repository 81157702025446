import React from "react";
import "../styles/InfoItem.css";
import SetupPlaceMapAuto from "./SetupPlaceMapAuto";
import SetupPlaceMapManual from "./SetupPlaceMapManual";
import { googleMapsApiKey } from "../constants.js";
import Script from "react-load-script";
import MapPins from "../pins/MapPins.js";

export default class SetupPlaceMap extends React.Component {
  state = {
    auto: true,
    payload: {},
    inputKey: null,
    showMap: false
  };

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  switchChildComponent = () => {
    this.setState({
      auto: !this.state.auto
    });
  };

  createMap = (lat, lng, selectedAddress) => {
    this.setState({
      showMap: true,
      selectedAddress: selectedAddress,
      resetContentKey: false
    });

    this.googlemap = new window.google.maps.Map(
      document.getElementById("googlemap"),
      {
        zoom: 16,
        center: {
          lat: lat, // 50.7225286, // this.state.lat,
          lng: lng // -1.7780999 //this.state.lng
        },
        disableDefaultUI: true,
        fullscreenControl: false,
        streetViewControl: false,
        mapTypeControl: false,
        zoomControl: false,
        gestureHandling: "none",
        styles: [
          {
            featureType: "administrative",
            elementType: "geometry",
            stylers: [
              {
                visibility: "off"
              }
            ]
          },
          {
            featureType: "poi",
            stylers: [
              {
                visibility: "off"
              }
            ]
          },
          {
            featureType: "road",
            elementType: "labels.icon",
            stylers: [
              {
                visibility: "off"
              }
            ]
          },
          {
            featureType: "transit",
            stylers: [
              {
                visibility: "on"
              }
            ]
          }
        ]
      }
    );

    this.marker = new window.google.maps.Marker({
      position: this.googlemap.getCenter(),
      map: this.googlemap,

      icon: {
        url: MapPins.home,
        scaledSize: new window.google.maps.Size(40, 54.5)
      },
      optimized: false,
      clickable: false,
      draggable: false
    });
  };

  handleRedirect = () => {
    this.props.history.push("/pick-theme");
  };

  onEdit = (e) => {
    e.preventDefault();
    this.setState({
      showMap: false,
      resetContentKey: true
    });
  };

  render() {
    const {
      SaveStatus,
      ItemLabel,
      propertyDetails,
      propertyId,
      onStateChange
    } = this.props;

    return (
      <>
        <div className="setupflow-heading-container">
          <div className="setupflow-heading">Your property's location</div>
        </div>
        <div className="infoitem-container">
          <div className={this.state.showMap ? "show" : "hide"}>
            <div className="setupflow-subheading">
              {this.state.selectedAddress}
            </div>
            <button
              className="setupflow-inline-edit-button"
              onClick={this.onEdit}
            >
              Edit
            </button>

            <>
              <Script
                url={`https://maps.googleapis.com/maps/api/js?key=${googleMapsApiKey}&libraries=places`}
              />

              <>
                <div
                  id="googlemap"
                  style={{ width: "250px", height: "250px" }}
                />
                <div style={{ height: "15px" }} />
              </>
            </>

            <div className="infoitem-form-inner-container-left">
              <button
                onClick={this.handleRedirect}
                className="infoitem-save-button"
              >
                Continue
              </button>
            </div>
          </div>
          <div className={this.state.showMap ? "hide" : "show"}>
            <div className="infoitem-form-container">
              <div className="infoitem-form">
                {this.state.auto && (
                  <SetupPlaceMapAuto
                    {...this.props}
                    propertyId={propertyId}
                    ItemLabel={ItemLabel}
                    SaveStatus={SaveStatus}
                    propertyDetails={propertyDetails}
                    InputLabelA={"Find your property"}
                    onStateChange={onStateChange}
                    onLocationUpdate={this.createMap}
                    handleRedirect={this.props.handleRedirect}
                    key={this.state.resetContentKey} // Allows component to be reset upon edit
                  />
                )}

                {!this.state.auto && (
                  <SetupPlaceMapManual
                    propertyId={propertyId}
                    ItemLabel={ItemLabel}
                    SaveStatus={SaveStatus}
                    propertyDetails={propertyDetails}
                    InputLabelA={"Property address"}
                    onStateChange={onStateChange}
                    onLocationUpdate={this.createMap}
                    handleRedirect={this.props.handleRedirect}
                    key={this.state.resetContentKey} // Allows component to be reset upon edit
                  />
                )}

                <div className="infoitem-form-inner-container-full">
                  <div
                    className="infoitem-inline-edit-button"
                    onClick={this.switchChildComponent}
                  >
                    {this.state.auto && <>Enter manually instead</>}
                    {!this.state.auto && <>Use automatic lookup instead</>}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}
