import React, { useState, useEffect } from "react";
//import ReactGA from "react-ga";
//import {
// googleAnalyticsId,
// dataApiRoot,
// workflowApiRoot,
// apiSecretToken
//} from "../constants.js";
import Loading from "./Loading";
import "../styles/DisplayCanvas.css";
import "../styles/LandingPage.css";
//import slideShowVideoUS from "../videos/airguide-slideshow-usa.mp4";

//ReactGA.initialize(googleAnalyticsId);

export default function AgentLandingPage(props) {
  // Scroll to top on page load & track page path

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    window.scrollTo(0, 0);
    setLoading(false);
  }, []);

  //  const affiliateTrackingLabel = window.location.pathname.split("/")[2];
  // localStorage.setItem("affiliateTrackingLabel", affiliateTrackingLabel);

  // const onButtonClick = () => {
  //   props.history.push(`/start`);
  // };

  // const onLinkClick = () => {
  //  const affiliateCampaignLabel = localStorage.getItem(
  //   "affiliateTrackingLabel"
  // );
  //  window.location.href = `https://airguideapp.com?utm_source=affiliate-landing-page&utm_medium=affiliate&utm_campaign=${affiliateCampaignLabel}`;
  //};

  if (loading) {
    return <Loading />;
  } else {
    return (
      <>
        <div className="landing-body-wrapper">
          {
            // Cover Container-->
          }
          <div className="landing-cover-outer-container">
            <div className="landing-cover-mid-container">
              <div className="landing-cover-inner-container">
                {
                  // Start Cover Image-->
                }

                {
                  // USA (Default) Cover Image-->
                }

                <div className="landing-cover-image-container">
                  {
                    // poster="https://cdn.airguideapp.com/images/airguide-slideshow-usa-poster.png"
                  }
                  <video
                    autoplay
                    loop
                    className="landing-cover-image-video"
                    id="slideshowVideo"
                    poster="https://cdn.airguideapp.com/images/app-circle-upload-video.png"
                    src={"slideShowVideoUS"}
                    type="video/mp4"
                    width="100%"
                    height="auto"
                  ></video>

                  {
                    //    <a
                    //     href="https://air.guide/sunshine-villa-demo"
                    //     target="_blank"
                    //     rel="noopener noreferrer"
                    //  >
                    //    <button
                    //     id="cover-view-example-button"
                    //     className="landing-example-button-cover"
                    //   >
                    //   <span>View </span> <span> example guide</span>
                    //   <img
                    //     className="landing-view-example-icon"
                    //     src="https://cdn.airguideapp.com/icons/upper-right-arrow-square.svg"
                    //     alt="link"
                    //    height="10px"
                    //    width="10px"
                    //  ></img>
                    //  </button>
                    // </a>
                  }
                </div>
                {
                  // End USA (Default)  Cover Image-->
                }

                {
                  // UK Cover Image-->
                }
                {
                  // <div className="landing-display-uk-assets">
                  //         <div className="landing-cover-image-container">
                  //            <video
                  //         className="landing-cover-image-video"
                  //           autoplay
                  //          loop
                  //          id="slideshowVideo
                  //        poster="https://cdn.airguideapp.com/images/airguide-slideshow-poster.png"
                  //       src="../videos/airguide-slideshow.mp4"
                  //     >
                  //     </video>
                  //      <a href="https://air.guide/demo" target="_blank"
                  //      rel="noopener noreferrer">
                  //       <button id="cover-view-example-button" className="landing-example-button-cover">
                  //         <span>View </span> <span> example guide</span>
                  //        <img
                  //        layout="fixed"
                  //       height="10px"
                  //      width="10px"
                  //       src="https://cdn.airguideapp.com/icons/upper-right-arrow-square.svg"
                  //     ></img>
                  //   </button>
                  //   </a>
                  //  </div>
                  // </div>
                }
                {
                  // End UK Cover Image-->
                }

                {
                  // End Cover Image-->
                }

                {
                  // Cover Content-->
                }
                <div className="landing-cover-content-container">
                  <h1 className="landing-h1">
                    Take the Effort out of Tenant Management
                  </h1>

                  <h2 className="landing-h2">
                    Streamline information sharing and simplify communication
                    with our award winning digital property guides.
                  </h2>

                  <div className="landing-cta-button-cover-wrapper">
                    <div className="landing-cta-button-cover-inner-wrapper">
                      <a href="https://airguideapp.com/book-demo.html">
                        <button
                          id="cover-start-trial"
                          className="landing-cta-button-cover"
                        >
                          Book Your 30 Minute Demo
                        </button>
                      </a>

                      {
                        //   <button
                        //    id="play-video-button"
                        //   className="landing-play-button"
                        //    on="tap:video-container.show,main-body.hide,myVideo720.play,play-video-button.hide,playerbutton1.show"
                        //  >
                        //   <div className="landing-play-button-text">
                        //     How it works
                        //   </div>
                        //   <div className="landing-play-button-icon-container">
                        //     <img
                        //  layout="responsive"
                        //     height="12px"
                        //     width="12px"
                        //     src="https://cdn.airguideapp.com/icons/play-triangle.svg"
                        //     alt="d"
                        //  ></img>
                        //  </div>
                        //  </button>
                      }
                    </div>
                  </div>

                  <div className="landing-cta-button-subtext">
                    Meet our friendly team and find out how easy it is.
                  </div>
                </div>
                {
                  // End Cover Content -->
                }
              </div>
              {
                // End "cover-inner-container"-->
              }
            </div>
            {
              // End "cover-mid-container"-->
            }
          </div>
          {
            // End "cover-outer-container"-->
          }

          {
            // <!-- Start "mini-banner"-->
          }
          <div className="landing-mini-banner-wrapper">
            <div className="landing-mini-banner">
              <div className="landing-mini-banner-text-wrapper">
                {
                  //  <div className="landing-mini-banner-heading">Pricing starts at just £5.99 // per month for your first property</div>
                }

                <div className="landing-mini-banner-heading">
                  Designed for HMOs | Residential Lets | Student Lets
                </div>

                {
                  // <div className="landing-mini-banner-heading">Pricing starts at just AUD //$11.99 per month for your first property</div>
                }

                {
                  //  <div className="landing-mini-banner-text-link">
                  //   <a id="mini-banner-text-link" href="#price-section">
                  //    View pricing
                  //  </a>
                  // </div>
                }
              </div>
            </div>
          </div>
          {
            //<!-- End "mini-banner"-->
          }

          {
            //<!-- Start 3-Box Banner -->
          }
          <div className="landing-box-banners-wrapper">
            {
              // <!-- 1st Banner-->
            }
            <div className="landing-box-banner-wrapper">
              <div className="landing-box-image-wrapper">
                <div className="landing-box-icon-image-wrapper">
                  <img
                    src="https://cdn.airguideapp.com/images/thumbs_up.svg"
                    height="auto"
                    width="100%"
                    alt="happy guest"
                  ></img>
                </div>
              </div>

              <div className="landing-box-text-wrapper">
                <h3 className="landing-h3">
                  Reduce admin <br></br>work
                </h3>
                <p className="landing-p">
                  Minimise effort and free up your time.
                </p>
              </div>
            </div>

            {
              //<!-- 2nd Banner -->
            }
            <div className="landing-box-banner-wrapper">
              <div className="landing-box-image-wrapper">
                <div className="landing-box-icon-image-wrapper">
                  <img
                    src="https://cdn.airguideapp.com/images/stars.svg"
                    height="auto"
                    width="100%"
                    alt="5-stars"
                  ></img>
                </div>
              </div>

              <div className="landing-box-text-wrapper">
                <h3 className="landing-h3">
                  Empower and delight <br></br>your tenants
                </h3>
                <p className="landing-p">
                  Everything your tenants need to know, all in one place.
                </p>
              </div>
            </div>

            {
              //<!-- 3rd Box -->
            }
            <div className="landing-box-banner-wrapper">
              <div className="landing-box-image-wrapper">
                <div className="landing-box-icon-image-wrapper">
                  <img
                    src="https://cdn.airguideapp.com/images/chat.svg"
                    height="auto"
                    width="100%"
                    alt="Choosing the right location for serviced accommodation"
                  ></img>
                </div>
              </div>

              <div className="landing-box-text-wrapper">
                <h3 className="landing-h3">
                  Streamline <br></br>communication
                </h3>
                <p className="landing-p">
                  Simple, organised communication. Available 24/7.
                </p>
              </div>
            </div>
          </div>
          {
            //<!-- End 3-Box Banner -->
          }

          {
            //Start Section Break Line -->
          }
          <div className="landing-section-break-wrapper">
            <div className="landing-section-break"></div>
          </div>
          {
            //{//  <!--  End Section Break Line -->
          }

          {
            //<!-- Start Review Banner Section -->
          }
          <div className="landing-badge-banner-heading">
            The #1 choice for professional property managers
          </div>
          {
            //<!-- Start 3-Box Banner -->
          }
          <div className="landing-box-banners-wrapper">
            {
              //<!-- 1st Banner-->
            }
            <div className="landing-box-banner-wrapper">
              <div className="landing-box-image-wrapper">
                <div className="landing-box-badge-image-wrapper">
                  <a
                    href="https://www.capterra.com/reviews/242066/airguide?utm_source=vendor&utm_medium=badge&utm_campaign=capterra_reviews_badge"
                    target="blank_"
                  >
                    <img
                      src="https://assets.capterra.com/badge/33d67a50595d7b53d232ad18432814cf.svg?v=2202927&p=242066"
                      alt="capterra-review-badge"
                    />
                  </a>
                </div>
              </div>
            </div>

            {
              //<!-- 2nd Banner f-->
            }
            <div className="landing-box-banner-wrapper">
              <div className="landing-box-image-wrapper">
                <div className="landing-box-badge-image-wrapper">
                  <a
                    href="https://www.getapp.com/hospitality-travel-software/a/airguide/reviews/"
                    target="blank_"
                  >
                    <img
                      src="https://www.getapp.com/ext/reviews_widget/v1/dark/airguide-application"
                      alt="getapp-review-badge"
                    />
                  </a>
                </div>
              </div>
            </div>

            {
              //<!-- 3rd Box -->
            }
            <div className="landing-box-banner-wrapper">
              <div className="landing-box-image-wrapper">
                <div className="landing-box-badge-image-wrapper">
                  <a
                    href="https://www.google.com/search?q=airguide&oq=airguide&aqs=chrome..69i57j35i39j0i512l6j46i175i199i512j0i512.1966j0j15&sourceid=chrome&ie=UTF-8#lrd=0x48739f01a3d14dc9:0x18adf41082660fd1,1,,,"
                    target="blank_"
                  >
                    <img
                      src="https://cdn.airguideapp.com/images/google-review-badge.png"
                      height="auto"
                      width="100%"
                      alt="google-review-badge"
                    ></img>
                  </a>
                </div>
              </div>
            </div>
          </div>
          {
            //<!-- End 3-Box Banner -->
          }

          {
            //<!-- CTA Button-->
          }
          <div className="landing-landing-cta-button-wrapper">
            <a href="./book-demo.html">
              <button
                id="mid-body-start-trial-4"
                className="landing-cta-button"
              >
                Book Your 30 Minute Demo
              </button>
            </a>
          </div>
          {
            //<!-- End CTA Button-->
          }
          {
            //<!-- End Review Banner Section -->
          }
          {
            //Start Section Break Line -->
          }
          <div className="landing-section-break-wrapper">
            <div className="landing-section-break"></div>
          </div>
          {
            //{//  <!--  End Section Break Line -->
          }
          {
            //<!-- Start Spacer -->
          }
          <div style={{ height: "0px" }}></div>
          {
            //<!-- End Spacer -->
          }

          {
            //Start new insert
          }

          <div className="landing-box-banners-heading">
            Empower and delight your tenants with our award winning app
          </div>
          {
            //  <!-- Start 3-Banner Module -->
          }
          <div className="landing-box-banners-wrapper">
            {
              //  <!-- 1st Banner-->
            }
            <div className="landing-box-banner-wrapper">
              <div className="landing-box-image-wrapper">
                <img
                  src="https://cdn.airguideapp.com/images/app-circle-upload-video.png"
                  width="100%"
                  height="auto"
                  alt="Customisable design"
                ></img>
              </div>

              <div className="landing-text-wrapper">
                <h3 className="landing-h3">
                  Property information - Up to date. Always accessible.
                </h3>
                <p className="landing-p">
                  Help tenants help themselves by uploading descriptions,
                  walk-through videos, PDF manuals and images.
                </p>
              </div>
            </div>

            {
              //  <!-- 2nd Banner -->
            }
            {
              //  <!--  src="https://cdn.airguideapp.com/images/app-circle-important-documents.png" -->
            }
            <div className="landing-box-banner-wrapper">
              <div className="landing-box-image-wrapper">
                <img
                  width="100%"
                  height="auto"
                  alt="important-documents"
                  src="https://cdn.airguideapp.com/images/app-circle-important-documents.png"
                ></img>
              </div>

              <div className="landing-text-wrapper">
                <h3 className="landing-h3">
                  Important documents, securely stored in one place
                </h3>
                <p className="landing-p">
                  Tenancy agreements and safety certificates easily accessible
                  by tenants and agents. Quickly update from anywhere, anytime.
                </p>
              </div>
            </div>

            {
              //  <!-- 3rd Banner -->
            }
            {
              //  <!--  src="https://cdn.airguideapp.com/images/app-circle-maintenance.png" -->
            }
            <div className="landing-box-banner-wrapper">
              <div className="landing-box-image-wrapper">
                <img
                  src="https://cdn.airguideapp.com//images/app-circle-maintenance.png"
                  width="100%"
                  height="auto"
                  alt="Customisable design"
                ></img>
              </div>

              <div className="landing-text-wrapper">
                <h3 className="landing-h3">Maintenance made simple</h3>
                <p className="landing-p">
                  Save time, with simplified, organised maintenance requests.
                </p>
              </div>
            </div>
            {
              //  <!-- End 3rd Banner -->
            }
          </div>
          {
            //  <!-- End 3-Banner Module-->
          }

          {
            //  <!-- Start 3-Banner Module -->
          }
          {
            //  <!--  src="https://cdn.airguideapp.com/images/app-circle-document-uploads.png" -->
          }
          <div className="landing-box-banners-wrapper">
            {
              //  <!-- 1st Banner-->
            }
            <div className="landing-box-banner-wrapper">
              <div className="landing-box-image-wrapper">
                <img
                  src="https://cdn.airguideapp.com//images/app-circle-document-uploads.png"
                  width="100%"
                  height="auto"
                  alt="Customisable design"
                ></img>
              </div>

              <div className="landing-text-wrapper">
                <h3 className="landing-h3">
                  Tenant enquiries and document uploads
                </h3>
                <p className="landing-p">
                  Receive enquiries and documents, with a digital log, stored in
                  one place.
                </p>
              </div>
            </div>

            {
              //  <!-- 2nd Banner -->
            }
            {
              //  <!--  src="https://cdn.airguideapp.com/images/app-circle-two-way-communication.png" -->
            }
            <div className="landing-box-banner-wrapper">
              <div className="landing-box-image-wrapper">
                <img
                  src="https://cdn.airguideapp.com//images/app-circle-two-way-communication.png"
                  width="100%"
                  height="auto"
                  alt="Customisable design"
                ></img>
              </div>

              <div className="landing-text-wrapper">
                <h3 className="landing-h3">
                  Two-way communication with tenants
                </h3>
                <p className="landing-p">
                  Our in-App messaging system, enables two way communication and
                  real-time updates.
                </p>
              </div>
            </div>

            {
              //  <!-- 3rd Banner -->
            }
            {
              //  <!--  src="https://cdn.airguideapp.com/images/app-circle-local-partners.png" -->
            }
            <div className="landing-box-banner-wrapper">
              <div className="landing-box-image-wrapper">
                <img
                  src="https://cdn.airguideapp.com//images/app-circle-local-partners.png"
                  width="100%"
                  height="auto"
                  alt="Customisable design"
                ></img>
              </div>

              <div className="landing-text-wrapper">
                <h3 className="landing-h3">
                  Earn more. Partner with local businesses.
                </h3>
                <p className="landing-p">
                  Boost income by referring your tenants to local businesses.
                </p>
              </div>
            </div>
            {
              //  <!-- End 3rd Banner -->
            }
          </div>
          {
            //  <!-- End 3-Banner Module-->
          }

          {
            //  <!-- CTA Button-->
          }
          <div className="landing-cta-button-wrapper">
            <a href="./book-demo.html">
              <button
                id="mid-body-start-trial-1"
                className="landing-cta-button"
              >
                Book Your 30 Minute Demo
              </button>
            </a>
          </div>
          {
            //  <!-- End CTA Button-->
          }

          {
            //  <!-- Start Section Break Line -->
          }
          <div className="landing-section-break-wrapper">
            <div className="landing-section-break"></div>
          </div>
          {
            //  <!--  End Section Break Line -->
          }

  {//        <div className="landing-box-banners-heading">How it works</div>
     //     {
     //       //  <!-- Start 3-Banner Module -->
     //     }
     //     <div className="landing-box-banners-wrapper">
      //      {
      //        //  <!-- 1st Banner-->
       //     }
       //     <div className="landing-box-banner-wrapper">
         //     <div className="landing-box-image-wrapper">
         //       <img
         //         src="https://cdn.airguideapp.com/images/app-circle-message.png"
         //         width="100%"
         //         height="auto"
         //         alt="Customisable design"
         //       ></img>
         //     </div>

         //     <div className="landing-text-wrapper">
          //      <h3 className="landing-h3">Share your branded weblink</h3>
          //      <p className="landing-p">
         //         You simply send your tenants a customised, unique URL. Your
          //        airguide™ app then loads automatically on any device, with
          //        nothing for them to download!
          //      </p>
           //   </div>
          //  </div>

          //  {
              //  <!-- 2nd Banner -->
           // }
           // <div className="landing-box-banner-wrapper">
          //    <div className="landing-box-image-wrapper">
         //       <img
          //        width="100%"
           //       height="auto"
           //       alt="qr-code"
          //        src="https://cdn.airguideapp.com/images/qr-insitu-circle.png"
          //      ></img>
          //    </div>

          //    <div className="landing-text-wrapper">
          //      <h3 className="landing-h3">
          //        QR codes for quick access to your app
          //      </h3>
          //      <p className="landing-p">
          //        You'll get custom QR codes that you can print out and display
          //        within your property. Tenants can simply scan with their phone
          //        to get instant access to your airguide™ app.
          //      </p>
          //    </div>
          //  </div>

          //  {
         //     //  <!-- 3rd Banner -->
         //   }
         //   <div className="landing-box-banner-wrapper">
         ////     <div className="landing-box-image-wrapper">
           //     <img
          //        src="https://cdn.airguideapp.com/images/app-circle-passcode-insitu.png"
          //        width="100%"
            //      height="auto"
            //      alt="Customisable design"
            //    ></img>
           //   </div>
//
           //   <div className="landing-text-wrapper">
            //    <h3 className="landing-h3">
             //     Add a passcode to keep your guides secure
             //   </h3>
             //   <p className="landing-p">
             //     Set a passcode for your guides - you can change it whenever
              //    you like so only tenants with the latest passcode can access
              //    your guides.
              //  </p>
             // </div>
           // </div>
          //  {
              //  <!-- End 3rd Banner -->
          //  }
         // </div>
         // {
         //   //  <!-- End 3-Banner Module-->
        //  }
        }




     {//     {
      //      //  <!-- Start Section Break Line -->
      //    }
      //    <div className="landing-section-break-wrapper">
      //      <div className="landing-section-break"></div>
       //   </div>
       //   {
       //     //<!--  End Section Break Line -->
       //   }
     }

          {
            //  <!--  Start Reviews Section -->
          }
          <div className="landing-box-banners-heading">Happy customers</div>
          {
            //  <!-- Start 3-Box Banner -->
          }
          <div className="landing-box-banners-wrapper">
            {
              //  <!-- 1st Banner-->
            }
            <div className="landing-box-banner-wrapper">
              {
                //   <div className="landing-avatar-image-wrapper-centre">
                //   <img
                //    src="https://cdn.airguideapp.com/images/riverside-lighthouse-300px.jpeg"
                //    className="landing-avatar-image"
                //   data-amp-auto-lightbox-disable
                //    height="90px"
                //   width="90px"
                //   layout="responsive"
                //  alt="airguide - The Digital Guest Book"
                //  ></img>
                //</div>
              }

              <div className="landing-box-text-wrapper">
                <p className="landing-p">
                  <strong>airguide is best in class</strong>
                  <br></br>
                  <br></br>"Awesome experience with the team. They've been
                  nothing but responsive and helpful and I can't wait to use
                  them for additional properties in the future."
                </p>
                <div className="landing-star-icons-wrapper">
                  <div className="landing-star-icon-image-wrapper">
                    <img
                      src="https://cdn.airguideapp.com/images/single-star.svg"
                      height="auto"
                      width="100%"
                      alt="5-star-rating"
                    ></img>
                  </div>
                  <div className="landing-star-icon-image-wrapper">
                    <img
                      src="https://cdn.airguideapp.com/images/single-star.svg"
                      height="auto"
                      width="100%"
                      alt="5-star-rating"
                    ></img>
                  </div>
                  <div className="landing-star-icon-image-wrapper">
                    <img
                      src="https://cdn.airguideapp.com/images/single-star.svg"
                      data-amp-auto-lightbox-disable
                      height="auto"
                      width="100%"
                      layout="responsive"
                      alt="5-star-rating"
                    ></img>
                  </div>
                  <div className="landing-star-icon-image-wrapper">
                    <img
                      src="https://cdn.airguideapp.com/images/single-star.svg"
                      height="auto"
                      width="100%"
                      alt="5-star-rating"
                    ></img>
                  </div>
                  <div className="landing-star-icon-image-wrapper">
                    <img
                      src="https://cdn.airguideapp.com/images/single-star.svg"
                      height="auto"
                      width="100%"
                      alt="5-star-rating"
                    ></img>
                  </div>
                </div>

                <div className="landing-review-link-no-link">Cody C</div>
              </div>
            </div>

            {
              //  <!-- 2nd Banner -->
            }
            <div className="landing-box-banner-wrapper">
              {
                //  <div className="landing-avatar-image-wrapper-centre">
                //  <img
                //   src="https://cdn.airguideapp.com/images/hut-in-vines-300px.jpeg"
                //  className="landing-avatar-image"
                //  data-amp-auto-lightbox-disable
                //   height="90px"
                //  width="90px"
                //  layout="responsive"
                //   alt="airguide - The Digital Guest Book"
                //  ></img>
                // </div>
              }
              <div className="landing-box-text-wrapper">
                <p className="landing-p">
                  <strong>Fantastic product. Very very happy customer </strong>
                  <br></br>
                  <br></br>"This is so easy to setup and use. All the features
                  are available that we need to create a very detailed guide
                  book for our properties."
                </p>
                <div className="landing-star-icons-wrapper">
                  <div className="landing-star-icon-image-wrapper">
                    <img
                      src="https://cdn.airguideapp.com/images/single-star.svg"
                      height="auto"
                      width="100%"
                      alt="5-star-rating"
                    ></img>
                  </div>
                  <div className="landing-star-icon-image-wrapper">
                    <img
                      src="https://cdn.airguideapp.com/images/single-star.svg"
                      data-amp-auto-lightbox-disable
                      height="auto"
                      width="100%"
                      alt="5-star-rating"
                    ></img>
                  </div>
                  <div className="landing-star-icon-image-wrapper">
                    <img
                      src="https://cdn.airguideapp.com/images/single-star.svg"
                      data-amp-auto-lightbox-disable
                      height="auto"
                      width="100%"
                      alt="5-star-rating"
                    ></img>
                  </div>
                  <div className="landing-star-icon-image-wrapper">
                    <img
                      src="https://cdn.airguideapp.com/images/single-star.svg"
                      data-amp-auto-lightbox-disable
                      height="auto"
                      width="100%"
                      alt="5-star-rating"
                    ></img>
                  </div>
                  <div className="landing-star-icon-image-wrapper">
                    <img
                      src="https://cdn.airguideapp.com/images/single-star.svg"
                      data-amp-auto-lightbox-disable
                      height="auto"
                      width="100%"
                      alt="5-star-rating"
                    ></img>
                  </div>
                </div>

                <div className="landing-review-link">Dan L</div>
              </div>
            </div>

            {
              //  <!-- 3rd Box -->
            }
            <div className="landing-box-banner-wrapper">
              {
                //  <div className="landing-avatar-image-wrapper-centre">
                //   <img
                //    src="https://cdn.airguideapp.com/images/zpower12345z-300px.jpeg"
                //    className="landing-avatar-image"
                //    data-amp-auto-lightbox-disable
                //    height="90px"
                //    width="90px"
                //    layout="responsive"
                //    alt="airguide - The Digital Guest Book"
                //  ></img>
                //</div>
              }
              <div className="landing-box-text-wrapper">
                <p className="landing-p">
                  <strong>Simple, functional, effective</strong>
                  <br></br>
                  <br></br>"Very easy to upload information and also easy to
                  continually add information as needed."
                </p>
                <div className="landing-star-icons-wrapper">
                  <div className="landing-star-icon-image-wrapper">
                    <img
                      src="https://cdn.airguideapp.com/images/single-star.svg"
                      height="auto"
                      width="100%"
                      alt="5-star-rating"
                    ></img>
                  </div>
                  <div className="landing-star-icon-image-wrapper">
                    <img
                      src="https://cdn.airguideapp.com/images/single-star.svg"
                      data-amp-auto-lightbox-disable
                      height="auto"
                      width="100%"
                      alt="5-star-rating"
                    ></img>
                  </div>
                  <div className="landing-star-icon-image-wrapper">
                    <img
                      src="https://cdn.airguideapp.com/images/single-star.svg"
                      data-amp-auto-lightbox-disable
                      height="auto"
                      width="100%"
                      alt="5-star-rating"
                    ></img>
                  </div>
                  <div className="landing-star-icon-image-wrapper">
                    <img
                      src="https://cdn.airguideapp.com/images/single-star.svg"
                      height="auto"
                      width="100%"
                      alt="5-star-rating"
                    ></img>
                  </div>
                  <div className="landing-star-icon-image-wrapper">
                    <img
                      src="https://cdn.airguideapp.com/images/single-star.svg"
                      height="auto"
                      width="100%"
                      alt="5-star-rating"
                    ></img>
                  </div>
                </div>
                <div className="landing-review-link">Colin S</div>
              </div>
            </div>
          </div>
          {
            //  <!-- End 3-Box Banner -->
          }

          {
            //  <!-- Start Section Break Line -->
          }
          <div className="landing-section-break-wrapper">
            <div className="landing-section-break"></div>
          </div>
          {
            //  <!--  End Section Break Line -->
          }

          <div className="landing-box-banners-heading">Happy tenants</div>
          {
            //  <!-- Start 3-Box Banner -->
          }
          <div className="landing-box-banners-wrapper">
            {
              //  <!-- 1st Banner-->
            }
            <div className="landing-box-banner-wrapper">
              <div className="landing-box-text-wrapper">
                <p className="landing-p">
                  "Easy to use with lots of information on the property. Simple
                  to report problems and communicate with the managing agent"
                </p>
                <div className="landing-star-icons-wrapper">
                  <div className="landing-star-icon-image-wrapper">
                    <img
                      src="https://cdn.airguideapp.com/images/single-star.svg"
                      height="auto"
                      width="100%"
                      alt="5-star-rating"
                    ></img>
                  </div>
                  <div className="landing-star-icon-image-wrapper">
                    <img
                      src="https://cdn.airguideapp.com/images/single-star.svg"
                      height="auto"
                      width="100%"
                      alt="5-star-rating"
                    ></img>
                  </div>
                  <div className="landing-star-icon-image-wrapper">
                    <img
                      src="https://cdn.airguideapp.com/images/single-star.svg"
                      height="auto"
                      width="100%"
                      alt="5-star-rating"
                    ></img>
                  </div>
                  <div className="landing-star-icon-image-wrapper">
                    <img
                      src="https://cdn.airguideapp.com/images/single-star.svg"
                      height="auto"
                      width="100%"
                      alt="5-star-rating"
                    ></img>
                  </div>
                  <div className="landing-star-icon-image-wrapper">
                    <img
                      src="https://cdn.airguideapp.com/images/single-star.svg"
                      height="auto"
                      width="100%"
                      alt="5-star-rating"
                    ></img>
                  </div>
                </div>

                <div className="landing-review-link">Lucy R</div>
              </div>
            </div>

            {
              //  <!-- 2nd Banner -->
            }
            <div className="landing-box-banner-wrapper">
              <div className="landing-box-text-wrapper">
                <p className="landing-p">
                  "Love the app. So easy to find documents, no need to contact
                  the agent, the app has it all."
                </p>
                <div className="landing-star-icons-wrapper">
                  <div className="landing-star-icon-image-wrapper">
                    <img
                      src="https://cdn.airguideapp.com/images/single-star.svg"
                      height="auto"
                      width="100%"
                      alt="5-star-rating"
                    ></img>
                  </div>
                  <div className="landing-star-icon-image-wrapper">
                    <img
                      src="https://cdn.airguideapp.com/images/single-star.svg"
                      height="auto"
                      width="100%"
                      alt="5-star-rating"
                    ></img>
                  </div>
                  <div className="landing-star-icon-image-wrapper">
                    <img
                      src="https://cdn.airguideapp.com/images/single-star.svg"
                      height="auto"
                      width="100%"
                      alt="5-star-rating"
                    ></img>
                  </div>
                  <div className="landing-star-icon-image-wrapper">
                    <img
                      src="https://cdn.airguideapp.com/images/single-star.svg"
                      height="auto"
                      width="100%"
                      alt="5-star-rating"
                    ></img>
                  </div>
                  <div className="landing-star-icon-image-wrapper">
                    <img
                      src="https://cdn.airguideapp.com/images/single-star.svg"
                      height="auto"
                      width="100%"
                      alt="5-star-rating"
                    ></img>
                  </div>
                </div>

                <div className="landing-review-link">Natalie S</div>
              </div>
            </div>

            {
              //  <!-- 3rd Box -->
            }
            <div className="landing-box-banner-wrapper">
              <div className="landing-box-text-wrapper">
                <p className="landing-p">
                  "A really cool app, it gave me comprehensive coverage of
                  everything I needed."
                </p>
                <div className="landing-star-icons-wrapper">
                  <div className="landing-star-icon-image-wrapper">
                    <img
                      src="https://cdn.airguideapp.com/images/single-star.svg"
                      height="auto"
                      width="100%"
                      alt="5-star-rating"
                    ></img>
                  </div>
                  <div className="landing-star-icon-image-wrapper">
                    <img
                      src="https://cdn.airguideapp.com/images/single-star.svg"
                      height="auto"
                      width="100%"
                      alt="5-star-rating"
                    ></img>
                  </div>
                  <div className="landing-star-icon-image-wrapper">
                    <img
                      src="https://cdn.airguideapp.com/images/single-star.svg"
                      height="auto"
                      width="100%"
                      alt="5-star-rating"
                    ></img>
                  </div>
                  <div className="landing-star-icon-image-wrapper">
                    <img
                      src="https://cdn.airguideapp.com/images/single-star.svg"
                      height="auto"
                      width="100%"
                      alt="5-star-rating"
                    ></img>
                  </div>
                  <div className="landing-star-icon-image-wrapper">
                    <img
                      src="https://cdn.airguideapp.com/images/single-star.svg"
                      height="auto"
                      width="100%"
                      alt="5-star-rating"
                    ></img>
                  </div>
                </div>
                <div className="landing-review-link">Steve H</div>
              </div>
            </div>
          </div>
          {
            //  <!-- End 3-Box Banner -->
          }
          {
            //  <!--  End Reviews Section -->
          }

          {
            //  <!-- Start 3-Banner Module -->
          }

          {
            //  <!-- End 3-Banner Module-->
          }

          {
            //  <!-- CTA Button-->
          }
          <div className="landing-cta-button-wrapper">
            <a href="./book-demo.html">
              <button
                id="mid-body-start-trial-2"
                className="landing-cta-button"
              >
                Book Your 30 Minute Demo
              </button>
            </a>
          </div>
          {
            //  <!-- End CTA Button-->
          }

          {
            //<!-- Start Pricing Section-->
          }

          {
            //<!-- End Pricing Section-->
          }

          {
            //  <!-- Start Section Break Line -->
            //  <!--  <div className="landing-section-break-wrapper"> -->
            //  <!--      <div className="landing-section-break"></div> -->
            //  <!--    </div> -->
            //  <!-- End Section Break Line -->
          }
        </div>

        {
          //<!-- End "main-body" -->
        }

        {
          //  <!-- Start Footer -->
        }
        <div className="landing-footer">
          <p className="landing-p">
            Copyright © 2023 Airguide Digital Ltd. Registered in England.
            Registered Company No: 13394346. All rights reserved.
          </p>
          <p className="landing-p">
            {
              //  <!--  <a href="../contact.html">Contact us</a>
              // <a href="../affiliates.html">Affiliates</a> -->
            }
            <a href="https://airguideapp.com/terms-of-service.html">
              Terms of service
            </a>
            <a href="https://airguideapp.com/privacy-policy.html">
              Privacy policy
            </a>
          </p>
        </div>
        {
          //  <!-- End Footer -->
        }

        {
          //Close new insert
        }

        {
          //Close main Div
        }
      </>
    );
  }
}
