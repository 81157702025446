import React from "react";
//import CheckmarkIcon from "../icons/CheckmarkIcon";
import SetupCheckMark from "./SetupCheckMark";
import "../styles/ImageBanners.css";

export default class SetupImageBanner extends React.Component {
  handleSubmit = () => {
    const { ItemData } = this.props;
    this.props.onStateChange(
      {
        ThemeLabel: ItemData.ThemeLabel,
        PropertyCoverImage: null,
        imageFilename: "",
        selectedImageFile: null
      },
      false,
      true
    );
  };

  render() {
    const { ItemData, SelectedTheme } = this.props;

    return (
      <>
        <div
          className={
            SelectedTheme === ItemData.ThemeLabel
              ? "image-banner-selected"
              : "image-banner"
          }
          onClick={this.handleSubmit}
        >
          <img
            className="image-banner-main-image"
            src={ItemData.ThemeCoverImageThumb}
            alt={ItemData.ThemeName}
          />

          <div className="image-banner-heading-and-chevron-wrapper">
            <div className="image-banner-chevron-icon-wrapper">
              {SelectedTheme === ItemData.ThemeLabel && (
                <SetupCheckMark
                  IsChecked={true}
                  IsRequired={true}
                  IsCustomContent={true}
                  IsNewCustomItem={false}
                />
              )}
            </div>
            <div className="image-banner-heading">{ItemData.ThemeName}</div>
          </div>
        </div>
      </>
    );
  }
}
