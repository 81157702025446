import React from "react";
import { websiteUrl } from "../constants.js";
import "../styles/Header.css";
import logo from "../images/airguide-logo-header.png";

export default function DashboardHeader(props) {
  const pathName = props.location.pathname;
  const showCta = pathName === "/us/airbnb-welcome-book" ? true : false;
  const showDemoCta = pathName === "/uk/agents" ? true : false;
  return (
    <>
      <div className="headerbar">
        <a href={websiteUrl}>
          <img src={logo} className="header-logo" alt="airguide Logo"></img>
        </a>
        {showCta && (
          <a href="https://my.airguideapp.com/create-account">
            <button id="header-start-trial" class="landing-header-cta-button">
              Free trial
            </button>
          </a>
        )}
        {showDemoCta && (
          <a href="https://airguideapp.com/book-demo.html">
            <button id="header-start-trial" class="landing-header-cta-button">
              Book Demo
            </button>
          </a>
        )}
      </div>
    </>
  );
}
