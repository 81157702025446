import React, { useState, useEffect } from "react";
//import ReactGA from "react-ga";
//import {
// googleAnalyticsId,
// dataApiRoot,
// workflowApiRoot,
// apiSecretToken
//} from "../constants.js";
import Loading from "./Loading";
import "../styles/DisplayCanvas.css";
import "../styles/LandingPage.css";
import slideShowVideoUS from "../videos/airguide-slideshow-usa.mp4";

//ReactGA.initialize(googleAnalyticsId);

export default function LandingPage(props) {
  // Scroll to top on page load & track page path

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    window.scrollTo(0, 0);
    setLoading(false);
  }, []);

  //  const affiliateTrackingLabel = window.location.pathname.split("/")[2];
  // localStorage.setItem("affiliateTrackingLabel", affiliateTrackingLabel);

  // const onButtonClick = () => {
  //   props.history.push(`/start`);
  // };

  // const onLinkClick = () => {
  //  const affiliateCampaignLabel = localStorage.getItem(
  //   "affiliateTrackingLabel"
  // );
  //  window.location.href = `https://airguideapp.com?utm_source=affiliate-landing-page&utm_medium=affiliate&utm_campaign=${affiliateCampaignLabel}`;
  //};

  if (loading) {
    return <Loading />;
  } else {
    return (
      <>
        <div className="landing-body-wrapper">
          {
            // Cover Container-->
          }
          <div className="landing-cover-outer-container">
            <div className="landing-cover-mid-container">
              <div className="landing-cover-inner-container">
                {
                  // Start Cover Image-->
                }

                {
                  // USA (Default) Cover Image-->
                }

                <div className="landing-cover-image-container">
                  <video
                    autoplay
                    loop
                    className="landing-cover-image-video"
                    id="slideshowVideo"
                    poster="https://cdn.airguideapp.com/images/airguide-slideshow-usa-poster.png"
                    src={slideShowVideoUS}
                    type="video/mp4"
                    width="100%"
                    height="auto"
                  ></video>

                  <a
                    href="https://air.guide/sunshine-villa-demo"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <button
                      id="cover-view-example-button"
                      className="landing-example-button-cover"
                    >
                      <span>View </span> <span> example guide</span>
                      <img
                        className="landing-view-example-icon"
                        src="https://cdn.airguideapp.com/icons/upper-right-arrow-square.svg"
                        alt="link"
                        height="10px"
                        width="10px"
                      ></img>
                    </button>
                  </a>
                </div>
                {
                  // End USA (Default)  Cover Image-->
                }

                {
                  // UK Cover Image-->
                }
                {
                  // <div className="landing-display-uk-assets">
                  //         <div className="landing-cover-image-container">
                  //            <video
                  //         className="landing-cover-image-video"
                  //           autoplay
                  //          loop
                  //          id="slideshowVideo
                  //        poster="https://cdn.airguideapp.com/images/airguide-slideshow-poster.png"
                  //       src="../videos/airguide-slideshow.mp4"
                  //     >
                  //     </video>
                  //      <a href="https://air.guide/demo" target="_blank"
                  //      rel="noopener noreferrer">
                  //       <button id="cover-view-example-button" className="landing-example-button-cover">
                  //         <span>View </span> <span> example guide</span>
                  //        <img
                  //        layout="fixed"
                  //       height="10px"
                  //      width="10px"
                  //       src="https://cdn.airguideapp.com/icons/upper-right-arrow-square.svg"
                  //     ></img>
                  //   </button>
                  //   </a>
                  //  </div>
                  // </div>
                }
                {
                  // End UK Cover Image-->
                }

                {
                  // End Cover Image-->
                }

                {
                  // Cover Content-->
                }
                <div className="landing-cover-content-container">
                  <h1>The #1 digital welcome book for Airbnb rentals</h1>

                  <h2>
                    Everything your guests need to know - all in one place
                  </h2>

                  <div className="landing-cta-button-cover-wrapper">
                    <div className="landing-cta-button-cover-inner-wrapper">
                      <a href="https://my.airguideapp.com/create-account">
                        <button
                          id="cover-start-trial"
                          className="landing-cta-button-cover"
                        >
                          Try for free
                        </button>
                      </a>

                      <button
                        id="play-video-button"
                        className="landing-play-button"
                        on="tap:video-container.show,main-body.hide,myVideo720.play,play-video-button.hide,playerbutton1.show"
                      >
                        <div className="landing-play-button-text">
                          How it works
                        </div>
                        <div className="landing-play-button-icon-container">
                          <img
                            //  layout="responsive"
                            height="12px"
                            width="12px"
                            src="https://cdn.airguideapp.com/icons/play-triangle.svg"
                            alt="d"
                          ></img>
                        </div>
                      </button>
                    </div>
                  </div>

                  <div className="landing-cta-button-subtext">
                    Try airguide™ free for 14 days, no credit card required.
                  </div>
                </div>
                {
                  // End Cover Content -->
                }
              </div>
              {
                // End "cover-inner-container"-->
              }
            </div>
            {
              // End "cover-mid-container"-->
            }
          </div>
          {
            // End "cover-outer-container"-->
          }

          {
            // <!-- Start "mini-banner"-->
          }
          <div className="landing-mini-banner-wrapper">
            <div className="landing-mini-banner">
              <div className="landing-mini-banner-text-wrapper">
                {
                  //  <div className="landing-mini-banner-heading">Pricing starts at just £5.99 // per month for your first property</div>
                }

                <div className="landing-mini-banner-heading">
                  Pricing starts at just $7.99 per month for your first property
                </div>

                {
                  // <div className="landing-mini-banner-heading">Pricing starts at just AUD //$11.99 per month for your first property</div>
                }

                <div className="landing-mini-banner-text-link">
                  <a id="mini-banner-text-link" href="#price-section">
                    View pricing
                  </a>
                </div>
              </div>
            </div>
          </div>
          {
            //<!-- End "mini-banner"-->
          }

          {
            //<!-- Start 3-Box Banner -->
          }
          <div className="landing-box-banners-wrapper">
            {
              // <!-- 1st Banner-->
            }
            <div className="landing-box-banner-wrapper">
              <div className="landing-box-image-wrapper">
                <div className="landing-box-icon-image-wrapper">
                  <img
                    src="https://cdn.airguideapp.com/images/thumbs_up.svg"
                    height="auto"
                    width="100%"
                    alt="happy guest"
                  ></img>
                </div>
              </div>

              <div className="landing-box-text-wrapper">
                <h3 className="landing-h3">Happy guest, happy host</h3>
                <p className="landing-p">
                  Our easy-to-use web app is your digital concierge, providing
                  all the information your guests need, day or night - meaning
                  less of your time spent answering questions, more time being
                  happy.
                </p>
              </div>
            </div>

            {
              //<!-- 2nd Banner -->
            }
            <div className="landing-box-banner-wrapper">
              <div className="landing-box-image-wrapper">
                <div className="landing-box-icon-image-wrapper">
                  <img
                    src="https://cdn.airguideapp.com/images/stars.svg"
                    height="auto"
                    width="100%"
                    alt="5-stars"
                  ></img>
                </div>
              </div>

              <div className="landing-box-text-wrapper">
                <h3 className="landing-h3">More 5-star reviews</h3>
                <p className="landing-p">
                  Whether you want to upload a video of how your washing machine
                  works, or include a map link to your favourite restaurant,
                  airguide™ lets you add those personal touches that ensure a
                  5-star experience every time.
                </p>
              </div>
            </div>

            {
              //<!-- 3rd Box -->
            }
            <div className="landing-box-banner-wrapper">
              <div className="landing-box-image-wrapper">
                <div className="landing-box-icon-image-wrapper">
                  <img
                    src="https://cdn.airguideapp.com/images/wallet.svg"
                    height="auto"
                    width="100%"
                    alt="Choosing the right location for serviced accommodation"
                  ></img>
                </div>
              </div>

              <div className="landing-box-text-wrapper">
                <h3 className="landing-h3">Repeat bookings</h3>
                <p className="landing-p">
                  A dedicated area for guest marketing, helps you secure repeat
                  direct bookings and promote your other properties, giving you
                  an edge over the competition and maximising your profits.
                </p>
              </div>
            </div>
          </div>
          {
            //<!-- End 3-Box Banner -->
          }

          {
            //<!-- Start Review Banner Section -->
          }
          <div className="landing-badge-banner-heading">
            The #1 choice for professional Airbnb hosts
          </div>
          {
            //<!-- Start 3-Box Banner -->
          }
          <div className="landing-box-banners-wrapper">
            {
              //<!-- 1st Banner-->
            }
            <div className="landing-box-banner-wrapper">
              <div className="landing-box-image-wrapper">
                <div className="landing-box-badge-image-wrapper">
                  <a
                    href="https://www.capterra.com/reviews/242066/airguide?utm_source=vendor&utm_medium=badge&utm_campaign=capterra_reviews_badge"
                    target="blank_"
                  >
                    {" "}
                    <img
                      border="0"
                      src="https://assets.capterra.com/badge/33d67a50595d7b53d232ad18432814cf.svg?v=2202927&p=242066"
                    />{" "}
                  </a>
                </div>
              </div>
            </div>

            {
              //<!-- 2nd Banner f-->
            }
            <div className="landing-box-banner-wrapper">
              <div className="landing-box-image-wrapper">
                <div className="landing-box-badge-image-wrapper">
                  <a
                    href="https://www.getapp.com/hospitality-travel-software/a/airguide/reviews/"
                    target="blank_"
                  >
                    {" "}
                    <img
                      border="0"
                      src="https://www.getapp.com/ext/reviews_widget/v1/dark/airguide-application"
                    />{" "}
                  </a>
                </div>
              </div>
            </div>

            {
              //<!-- 3rd Box -->
            }
            <div className="landing-box-banner-wrapper">
              <div className="landing-box-image-wrapper">
                <div className="landing-box-badge-image-wrapper">
                  <a
                    href="https://www.google.com/search?q=airguide&oq=airguide&aqs=chrome..69i57j35i39j0i512l6j46i175i199i512j0i512.1966j0j15&sourceid=chrome&ie=UTF-8#lrd=0x48739f01a3d14dc9:0x18adf41082660fd1,1,,,"
                    target="blank_"
                  >
                    <img
                      src="https://cdn.airguideapp.com/images/google-review-badge.png"
                      height="auto"
                      width="100%"
                      alt="google-review-badge"
                    ></img>
                  </a>
                </div>
              </div>
            </div>
          </div>
          {
            //<!-- End 3-Box Banner -->
          }
          {
            //<!-- End Review Banner Section -->
          }

          {
            //<!-- Start Spacer -->
          }
          <div style={{ height: "45px" }}></div>
          {
            //<!-- End Spacer -->
          }
        </div>
      </>
    );
  }
}
