import React from "react";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect
} from "react-router-dom";
import ReactGA from "react-ga4";
import { hotjar } from "react-hotjar";
import {
  dataApiRoot,
  workflowApiRoot,
  //googleAnalyticsId,
  apiSecretToken,
  storageRootUrl,
  storageApiKey
} from "./constants.js";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import DashboardHeader from "./components/DashboardHeader";
import Loading from "./components/Loading";
//import PartnersLanding from "./components/PartnersLanding";
import PartnersLandingRedirect from "./components/PartnersLandingRedirect";
import PartnersLandingBespoke from "./components/PartnersLandingBespoke";
import LandingPage from "./components/LandingPage";
import AgentLandingPage from "./components/AgentLandingPage";
import ReferralLanding from "./components/ReferralLanding";
import SetupPlace from "./components/SetupPlace";
import SetupAccount from "./components/SetupAccount";
import SetupPlaceMap from "./components/SetupPlaceMap";
import SetupSteps from "./components/SetupSteps";
import Welcome from "./components/Welcome";
import SetupTheme from "./components/SetupTheme";
import SetupThemeColours from "./components/SetupThemeColours";

//ReactGA.initialize(googleAnalyticsId, {
//  gaOptions: {
//   allowLinker: true
//  }
//});

//ReactGA.ga("require", "linker");
//ReactGA.ga("linker:autoLink", ["airguideapp.com", "www.airguideapp.com"]);

export default class App extends React.Component {
  state = {
    payload: {
      AffiliateSource: localStorage.getItem("affiliateTrackingLabel")
        ? localStorage.getItem("affiliateTrackingLabel")
        : "",
      ThemeLabel: "seaside",
      PropertyCoverImage: null,
      PropertyHexPrimary: null,
      PropertyHexSecondary: null,
      PropertyID: "",
      PropertyTitle: "",
      GeoLocationAddressLineOne: "",
      GeoLocationAddressLineTwo: "",
      GeoLocationTownCity: "",
      GeoLocationCounty: "",
      GeoLocationState: "",
      GeoLocationPostcode: "",
      GeoLocationCountry: "",
      GeoLocationFormattedAddress: "",
      PropertyDisplayAddress: "",
      GeoLocationLat: null,
      GeoLocationLng: null,
      imageFilename: "",
      email: "",
      FreeOfferDays: localStorage.getItem("offerDurationDays")
        ? parseInt(localStorage.getItem("offerDurationDays"), 10)
        : 14
    },
    activeSession: false,
    loading: true
  };

  async componentDidMount() {
    this.setState(() => ({
      loading: false
    }));
    window.scrollTo(0, 0);
    const response = await fetch(`${dataApiRoot}theme?sort_field=rank`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${apiSecretToken}`
      }
    });
    const json = await response.json();
    this.setState({ themeData: json.response.results });
    //  ReactGA.pageview(window.location.pathname + window.location.search);
    hotjar.initialize("2966546", 6);
  }

  componentDidUpdate = () => {
    // ReactGA.pageview(window.location.pathname + window.location.search);
  };

  handleStateChange = (payload, submit, activeSession) => {
    var existingPayload = { ...this.state.payload };
    this.setState(
      {
        payload: { ...existingPayload, ...payload },
        activeSession: activeSession
      },
      () => {
        if (submit) {
          this.handleFinalSubmit();
        }
      }
    );
  };

  trackEvent = (category, action, label) => {
    ReactGA.event({
      category: category,
      action: action,
      label: label
    });
  };

  handleFinalSubmit = () => {
    this.setState(() => ({
      submissionResponseStatus: "fetching",
      submissionResponseTwoStatus: "fetching"
    }));

    // const emailLabel = this.state.payload.email.replace(/[^a-zA-Z0-9]/g, "_");
    // const propertyIdLabel = this.state.payload.PropertyID;
    // const gaLabel = `${emailLabel}_${propertyIdLabel}`;
    // console.log("gaLabel", gaLabel)
    // this.trackEvent("User", "Trial Signup", gaLabel);

    this.trackEvent("User", "Trial Signup", this.state.payload.PropertyID);

    console.log("Submitted Payload", this.state.payload);
    fetch(`${workflowApiRoot}initial-account-setup`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${apiSecretToken}`
      },
      body: JSON.stringify(this.state.payload)
    })
      .then((response) => response.json())
      .then((results) => {
        console.log("Submission Response", results);
        if (results.statusCode === 400) {
          this.setState(() => ({
            submissionResponseStatus: "error",
            submissionResponseReason: results.reason
          }));
        }
        if (results.status === "success") {
          sessionStorage.setItem("PropertyID", this.state.payload.PropertyID);
          sessionStorage.setItem(
            "UserFirstname",
            this.state.payload.UserFirstname
          );
          localStorage.setItem("login_token", results.response.token);
          this.setState(() => ({
            submissionResponseStatus: "success"
          }));
          //   console.log("results.response.results", results);

          if (this.state.payload.imageFilename) {
            fetch(
              `${storageRootUrl}/airguide/user/${results.response.user_id}/property-content/${results.response.PropertyId}/property-cover-image_${this.state.payload.imageFilename}`,
              {
                method: "PUT",
                headers: {
                  "Content-Type": "application/octet-stream",
                  AccessKey: `${storageApiKey}`,
                  Accept: "application/octet-stream"
                },
                body: this.state.payload.selectedImageFile
              }
            )
              .then((response) => response.json())
              .then((data) => {
                //   console.log("uploadFile data", data.HttpCode);
                if (data.HttpCode === 201) {
                }
                if (data.HttpCode !== 201) {
                }
              });
          }

          fetch(`${workflowApiRoot}full-account-setup`, {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Accept: "application/json",
              Authorization: `Bearer ${results.response.token}`
            },
            body: JSON.stringify(results.response)
          })
            .then((response) => response.json())
            .then((results) => {
              console.log("2nd api results", results);

              if (results.status !== "success") {
                this.setState(() => ({
                  submissionResponseTwoStatus: "error",
                  submissionResponseTwoReason: results.reason
                }));
              }
              if (results.status === "success") {
                this.setState(() => ({
                  submissionResponseTwoStatus: "success"
                }));
              }
            });
        }
      });
  };

  render() {
    if (this.state.loading === true) {
      return <Loading />;
    }
    // console.log("this.state", this.state);
    console.log("Payload", this.state.payload);
    //  console.log(localStorage.getItem("referralTrackingLabel"));

    (function () {
      var s = document.createElement("script");
      s.type = "text/javascript";
      s.src = "https://code.tidio.co/geiuzaxejdaoswynvecseaxionsablmv.js";
      s.async = true;

      document.body.append(s);
    })();

    return (
      <Router>
        <>
          <Route
            exact
            path={["/*"]}
            render={(props) => (
              <>
                <DashboardHeader {...props} />
              </>
            )}
          />

          <Route
            exact
            path={["/loading"]}
            render={(props) => (
              <>
                <Loading />
              </>
            )}
          />

          <Route
            exact
            path="/free/:affiliateTrackingLabel"
            render={(props) => (
              <>
                <ReferralLanding {...props} />
              </>
            )}
          />

          <Route exact path="/" render={() => <Redirect to="/start" />} />

          {/*   */}
          {!this.state.activeSession && (
            <Route
              exact
              path={[
                "/find-location",
                "/pick-theme",
                "/pick-branding",
                "/complete-setup"
              ]}
              render={() => <Redirect to="/start" />}
            />
          )}

          <div className="canvas-with-header-and-footer">
            <div className="canvas-with-header-and-footer-inner-container">
              <Route
                exact
                path={[
                  "/start",
                  "/find-location",
                  "/pick-theme",
                  "/pick-branding",
                  "/complete-setup"
                ]}
                render={(props) => (
                  <>
                    <SetupSteps {...props} />
                  </>
                )}
              />

              <Route
                render={({ location }) => (
                  <TransitionGroup>
                    <CSSTransition
                      key={location.path}
                      classNames="fade"
                      timeout={200}
                    >
                      <Switch>
                        <Route
                          exact
                          path="/welcome"
                          render={(props) => (
                            <Welcome
                              {...props}
                              onStateChange={this.handleStateChange}
                              handleFinalSubmit={this.handleFinalSubmit}
                              submissionResponseStatus={
                                this.state.submissionResponseStatus
                              }
                              submissionResponseReason={
                                this.state.submissionResponseReason
                              }
                              submissionResponseTwoStatus={
                                this.state.submissionResponseTwoStatus
                              }
                              VanityPropertyId={this.state.payload.PropertyID}
                            />
                          )}
                        />

                        <Route
                          exact
                          path="/start"
                          render={(props) => (
                            <div>
                              <SetupPlace
                                {...props}
                                onStateChange={this.handleStateChange}
                              />
                            </div>
                          )}
                        />

                        <Route
                          exact
                          path="/find-location"
                          render={(props) => (
                            <SetupPlaceMap
                              {...props}
                              ItemType={"property"} // Defines which dispatch to invoke
                              ItemLabel={"property-location"}
                              ItemHeading={"Property Location"}
                              ItemDescription={
                                "The location entered here controls where your property appears on the map"
                              }
                              InputLabelA={"Property address"}
                              IsRequired={false}
                              FalseText={"Not selected"}
                              PayloadA={"PropertyLocation"} // Defines which database label to update
                              IsNewCustomItem={true}
                              onStateChange={this.handleStateChange}
                              AppState={this.state}
                            />
                          )}
                        />

                        <Route
                          exact
                          path="/pick-theme"
                          render={(props) => (
                            <SetupTheme
                              {...props}
                              SelectedTheme={this.state.payload.ThemeLabel}
                              onStateChange={this.handleStateChange}
                              themeData={this.state.themeData}
                              selectedPropertyCoverImage={
                                //   this.state.payload.PropertyCoverImage
                                this.state.selectedFile
                                // this.state.payload.selectedImageFile
                              }
                            />
                          )}
                        />

                        <Route
                          exact
                          path="/pick-branding"
                          render={(props) => (
                            <SetupThemeColours
                              {...props}
                              SelectedTheme={this.state.payload.ThemeLabel}
                              onStateChange={this.handleStateChange}
                              themeData={this.state.themeData}
                            />
                          )}
                        />

                        <Route
                          exact
                          path="/complete-setup"
                          render={(props) => (
                            <SetupAccount
                              {...props}
                              onStateChange={this.handleStateChange}
                              handleFinalSubmit={this.handleFinalSubmit}
                              submissionResponseStatus={
                                this.state.submissionResponseStatus
                              }
                              submissionResponseReason={
                                this.state.submissionResponseReason
                              }
                            />
                          )}
                        />

                        <Route
                          exact
                          path="/:affiliateTrackingLabel"
                          render={(props) => (
                            <>
                              <ReferralLanding {...props} />
                            </>
                          )}
                        />

                        <Route
                          exact
                          path="/partners/:affiliateTrackingLabel"
                          render={(props) => (
                            <>
                              <PartnersLandingRedirect {...props} />
                            </>
                          )}
                        />

                        <Route
                          exact
                          path="/special-offer/:affiliateTrackingLabel"
                          render={(props) => (
                            <>
                              <PartnersLandingRedirect {...props} />
                            </>
                          )}
                        />

                        <Route
                          exact
                          path="/offer/:affiliateTrackingLabel"
                          render={(props) => (
                            <>
                              <PartnersLandingBespoke
                                {...props}
                                onStateChange={this.handleStateChange}
                              />
                            </>
                          )}
                        />

                        <Route
                          exact
                          path="/us/airbnb-welcome-book"
                          render={(props) => (
                            <>
                              <LandingPage
                                {...props}
                                // onStateChange={this.handleStateChange}
                              />
                            </>
                          )}
                        />

                        <Route
                          exact
                          path="/uk/agents"
                          render={(props) => (
                            <>
                              <AgentLandingPage
                                {...props}
                                // onStateChange={this.handleStateChange}
                              />
                            </>
                          )}
                        />
                      </Switch>
                    </CSSTransition>
                  </TransitionGroup>
                )}
              />
            </div>
          </div>

          {this.state.submissionResponseStatus === "success" && (
            <Route
              exact
              path="/*"
              render={() => (
                <>
                  <Redirect from="/*" to="/welcome" />
                </>
              )}
            />
          )}
        </>
      </Router>
    );
  }
}
