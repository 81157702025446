import React from "react";
import { appRootUrl, dashboardUrl } from "../constants.js";
import SetupCheckMark from "./SetupCheckMark";
import CTAButtonIconLeft from "./CTAButtonIconLeft";
import IFrameLoading from "./IFrameLoading";
import UpperRightArrowSquare from "../icons/UpperRightArrowSquare";
import "../styles/DisplayCanvas.css";
import "../styles/InfoPage.css";
import "../styles/SetupFlow.css";
import "../styles/Welcome.css";
import "../styles/PhoneBackground.css";
import "../styles/Buttons.css";
import whitePhone from "../images/phone-white.png";

export default class Welcome extends React.Component {
  componentDidMount() {
    window.scrollTo(0, 0);
  }

  goToDashboard = () => {
    const loginToken = localStorage.getItem("login_token");
    window.location.href = `${dashboardUrl}?login_token=${loginToken}`;
  };

  render() {
    const vanityPropertyId = sessionStorage.getItem("PropertyID");
    const userFirstname = sessionStorage.getItem("UserFirstname");
    const offerMessage = localStorage.getItem("offerMessage")
      ? localStorage.getItem("offerMessage")
      : "free 14 day trial";
    const clickTarget = `${appRootUrl}${vanityPropertyId}`;
    const { submissionResponseTwoStatus } = this.props;
    // console.log(localStorage.getItem("login_token"))

    //<!-- Start TradeDoubler Conversion Tag Insert on confirmation Page -->
    //(function () {
      window.tdconv("init", "2323254", { element: "iframe" });
      window.tdconv("track", "sale", {
        transactionId: `${vanityPropertyId}`,
        ordervalue: 0,
        voucher: "none",
        currency: "GBP",
        event: 425503
      });
      //  })();
      //<!-- End TradeDoubler tag-->

    return (
      <>
        <div className="welcome-page-container">
          <div className="welcome-top-boxes-container">
            <div className="welcome-left-box-container">
              <div className="welcome-heading">
                Welcome to airguide
                <span className="welcome-heading-tm">™</span>
              </div>

              <div className="setupflow-subheading">
                {userFirstname && (
                  <>{userFirstname}, your digital guidebook is ready!</>
                )}

                {!userFirstname && <>Your digital guidebook is ready!</>}
              </div>

              <div className="welcome-check-container">
                <SetupCheckMark
                  ItemText={`Your ${offerMessage} has been activated`}
                />
              </div>

              <div className="welcome-check-container">
                <SetupCheckMark
                  ItemText={
                    "We've created your guidebook using the info you've provided, along with some example content (you can edit this at any time)"
                  }
                />
              </div>
              <div className="welcome-check-container">
                <SetupCheckMark
                  ItemText={
                    "All features are available during the trial period, no Ads, no limitations - you can share it with guests right away!"
                  }
                />
              </div>
              <button
                className="welcome-cta-button"
                onClick={this.goToDashboard}
              >
                Go to dashboard
              </button>
            </div>

            <div className="welcome-right-box-container">
              <div className="iframe-phone-container">
                <img
                  className="iframe-phone-background"
                  src={whitePhone}
                  alt="App Preview"
                ></img>

                {submissionResponseTwoStatus !== "fetching" && (
                  <iframe
                    title="Your airguide preview"
                    className="iframe-phone-screen"
                    src={`${appRootUrl}${vanityPropertyId}`}
                    frameBorder="0"
                    height="406px"
                    width="187.5px"
                  ></iframe>
                )}

                {submissionResponseTwoStatus === "fetching" && (
                  <div className="iframe-phone-screen">
                    <IFrameLoading />
                  </div>
                )}
              </div>
              <div className="view-fullscreen-cta-button-wrapper">
                <a
                  href={`${clickTarget}`}
                  style={{ textDecoration: "none" }}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <CTAButtonIconLeft
                    Icon={UpperRightArrowSquare}
                    Style={"inverted"}
                    Heading={"View fullscreen"}
                  />
                </a>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}
