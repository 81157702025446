import React from "react";
import Loading from "./Loading";
import "../styles/DisplayCanvas.css";

export default class PartnersLandingRedirect extends React.Component {
  state = {
    affiliateTrackingLabel: ""
  };
  // Scroll to top on page load & track page path
  componentDidMount = () => {
    const affiliateTrackingLabel = window.location.pathname.split("/")[2];
    localStorage.setItem("affiliateTrackingLabel", affiliateTrackingLabel);
    this.setState({
      affiliateTrackingLabel: affiliateTrackingLabel
    });
  };

  render() {
    if (this.state.affiliateTrackingLabel) {
      window.location.href = `https://try.airguideapp.com/offer/${this.state.affiliateTrackingLabel}?utm_source=affiliate-landing-page&utm_medium=affiliate&utm_campaign=${this.state.affiliateTrackingLabel}`;
    } else return <Loading />;
  }
}
