import React from "react";
import "../styles/SetupFlowSteps.css";

export default function SetupSteps(props) {
  return (
    <>
      <div className="step-outer-wrapper">
        <div className="step-inner-wrapper">
          <div>
            <div
              className={
                props.match.path === "/start" ||
                props.match.path === "/find-location"
                  ? "number-bullet"
                  : "number-bullet-inverted"
              }
            >
              <div
                className={
                  props.match.path === "/start" ||
                  props.match.path === "/find-location"
                    ? "number-bullet-text"
                    : "number-bullet-text-inverted"
                }
              >
                1
              </div>
            </div>
            <div className="step-text"> Create place </div>
          </div>

          <div className="step-line"></div>

          <div>
            <div
              className={
                props.match.path === "/pick-theme" ||
                props.match.path === "/pick-branding"
                  ? "number-bullet"
                  : "number-bullet-inverted"
              }
            >
              <div
                className={
                  props.match.path === "/pick-theme" ||
                  props.match.path === "/pick-branding"
                    ? "number-bullet-text"
                    : "number-bullet-text-inverted"
                }
              >
                2
              </div>
            </div>
            <div className="step-text"> Pick a theme</div>
          </div>

          <div className="step-line"></div>
          <div>
            <div
              className={
                props.match.path === "/complete-setup"
                  ? "number-bullet"
                  : "number-bullet-inverted"
              }
            >
              <div
                className={
                  props.match.path === "/complete-setup"
                    ? "number-bullet-text"
                    : "number-bullet-text-inverted"
                }
              >
                3
              </div>
            </div>

            <div className="step-text"> Complete setup </div>
          </div>
        </div>
      </div>
    </>
  );
}
